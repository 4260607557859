import { SVG } from '../SVG'

const BriefcaseIcon = ({ style={}, ...props }) => (
  <SVG
    width="33"
    height="30"
    viewBox="0 0 33 30"
    fill="var(--primary-color)"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: "var(--primary-color)",...style }}
    {...props}
  >
    <path d="M29.558 6.27158H21.9674V4.29075C21.9674 3.22243 21.5451 2.20976 20.7894 1.45304C20.0336 0.696312 19.0223 0.273438 17.9554 0.273438H14.7325C13.6655 0.273438 12.6542 0.696312 11.8985 1.45304C11.1428 2.20976 10.7204 3.22243 10.7204 4.29075V6.27158H3.0965C1.72953 6.27158 0.618164 7.38441 0.618164 8.75319V15.7195C0.618164 16.0311 0.862664 16.2759 1.17384 16.2759H1.56282V26.0132C1.56282 27.7047 2.9298 29.0734 4.61906 29.0734H28.0354C29.7247 29.0734 31.0917 27.7047 31.1028 26.0132V16.2759H31.4807C31.7918 16.2759 32.0363 16.02 32.0363 15.7195V8.75319C32.0363 7.38441 30.925 6.27158 29.558 6.27158ZM11.8318 4.29075C11.8318 3.5229 12.1319 2.78843 12.6764 2.24314C13.221 1.69786 13.9545 1.39739 14.7213 1.39739H17.9443C18.7111 1.39739 19.4446 1.69786 19.9892 2.24314C20.5338 2.78843 20.8338 3.5229 20.8338 4.29075V6.27158H18.9667V4.29075C18.9667 4.01254 18.8556 3.75659 18.6667 3.56741C18.4666 3.37823 18.211 3.26695 17.9443 3.26695H14.7213C14.4435 3.26695 14.1879 3.37823 13.999 3.56741C13.81 3.76772 13.6989 4.02367 13.6989 4.29075V6.27158H11.8318V4.29075ZM17.8554 6.27158H14.8102V4.3909H17.8554V6.27158ZM1.72953 8.75319C1.72953 7.99647 2.34077 7.38441 3.0965 7.38441H29.558C30.3137 7.38441 30.925 7.99647 30.925 8.75319V15.1631H20.1448V14.0836C20.1448 13.772 19.9003 13.5272 19.5891 13.5272H13.0765C12.7653 13.5272 12.5208 13.772 12.5208 14.0836V15.1631H1.72953V8.75319ZM17.2552 17.9006V27.9606H15.3993V17.9006H17.2552ZM13.6322 16.7878V14.6512H19.0223V15.7195C19.0223 15.7584 19.0256 15.7963 19.0334 15.833V16.7878H13.6322ZM2.67418 26.0132V16.2759H12.5208V17.3442C12.5208 17.6558 12.7653 17.9006 13.0765 17.9006H14.2879V27.9606H4.61906C3.54104 27.9606 2.67418 27.0815 2.67418 26.0132ZM29.9914 26.0132C29.9914 27.0926 29.1135 27.9606 28.0466 27.9606H18.3666V17.9006H19.578C19.8892 17.9006 20.1337 17.6558 20.1448 17.3442V16.2759H29.9914V26.0132Z" />
  </SVG>
)

export default BriefcaseIcon
