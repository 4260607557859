import WithdrawIcon from '@nv/images/design/withdraw-icon'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import WithdrawCandicacyModalStyles from './WithdrawCandicacyModal.module.scss'

export const WithdrawCandicacyModal = ({ modalOpen, onClose, confirm, title }) => {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      consent: false,
    },
    onSubmit: () => {
      confirm()
    },
  })

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.confirm'),
      onClick: formik.handleSubmit,
    },
  ]

  return (
    <ModalWrapper isOpen={modalOpen} onRequestClose={onClose} headerLabel={t('Withdraw candidacy')} btns={btns}>
      <div className={WithdrawCandicacyModalStyles['container']}>
        <WithdrawIcon />
        <span className={WithdrawCandicacyModalStyles['title']}>
          {t(`You're about to withdraw your candidacy for ${title}. This action is irreversible.`)}
        </span>
      </div>
    </ModalWrapper>
  )
}
