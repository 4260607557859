import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { FormikError } from '../formik_error/FormikError'
import TextAreaFieldStyles from './TextAreaField.module.scss'

export const TextAreaField = ({
  customClass = undefined,
  formik = undefined,
  field = undefined,
  placeholder = undefined,
  label = undefined,
  isRequired = undefined,
  noLabel = false,
  cols = undefined,
  rows = undefined,
  customStyling = undefined,
  maxChars = undefined,
}) => {
  const [charCount, setCharCount] = useState(formik?.values[field]?.length || 0)

  useEffect(() => {
    setCharCount(formik?.values[field]?.length || 0)
  }, [formik?.values[field]])

  const handleChange = (e) => {
    let value = e.target.value
    if (maxChars && value.length > maxChars) {
      value = value.slice(0, maxChars)
    }
    setCharCount(value.length)
    formik.setFieldValue(field, value)
  }

  return (
    <div className={TextAreaFieldStyles['textarea__container']}>
      {!noLabel && (
        <label className={isRequired ? clsx(TextAreaFieldStyles['label'], TextAreaFieldStyles['required']) : TextAreaFieldStyles['label']}>
          {label}
        </label>
      )}

      <textarea
        style={customStyling || {}}
        rows={rows || '4'}
        cols={cols || '50'}
        placeholder={placeholder}
        name={field}
        autoComplete="off"
        value={formik.values[field] || ''}
        onChange={handleChange}
        onBlur={() => formik.setFieldTouched(field, true)}
        className={clsx(
          TextAreaFieldStyles['TextAreaField'],
          customClass,
          { [TextAreaFieldStyles['is-invalid']]: formik?.touched[field] && formik?.errors[field] },
          { [TextAreaFieldStyles['is-valid']]: formik?.touched[field] && !formik?.errors[field] },
        )}
      />

      {maxChars && (
        <div className={clsx(TextAreaFieldStyles['max-chars'], charCount >= maxChars && [TextAreaFieldStyles['max-chars__limit-reached']])}>
          {charCount}/{maxChars}
        </div>
      )}

      {formik && formik.touched[field] && formik.errors[field] && (
        <FormikError formik={formik} fieldName={field} errorLabel={formik.errors[field]} />
      )}
    </div>
  )
}
