import { useEffect } from 'react'
import { KeycloakAdminService } from '@nv/auth'
import { useAdminUserContext } from '@nv/contexts'
import { useQuery } from '@nv/hooks'
import { AdminVoterAuthService } from '@nv/services'
import { AdminVoterService } from '@nv/services'
import { beforeUnload } from '@nv/utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const useAdminKeycloakAuth = () => {
  const { setAdminUser, setTiviLink, initialized, setInitialized } = useAdminUserContext()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  useEffect(() => {
    const initializeSSO = async () => {
      if (!initialized) {
        KeycloakAdminService.initKeycloak(() => {
          setInitialized(true)
        })
      }
      // if keycloak initialization times out in error
      setTimeout(() => {
        setInitialized(true)
      }, 5000)
    }
    if (process.env.NX_PUBLIC_PUBLIC_URL == null) throw new Error('Missing .env file')
    else initializeSSO()
  }, [])

  const history = useHistory()
  const query = useQuery()

  const setLoggedInLocal = async () => {
    if (
      window.location.pathname.startsWith('/admin') &&
      (window.location.pathname.includes('login') || window.location.pathname.includes('auth') || query?.get('session_state') !== '')
    ) {
      if (KeycloakAdminService?.hasRole('tivi-contactinfo')) {
        history.push('/admin/electors-list')
      } else {
        history.push('/admin/dashboard')
      }
    }

    const tokenParsed = KeycloakAdminService.getTokenParsed()
    if (tokenParsed) {
      setAdminUser({
        ...tokenParsed,
        username: tokenParsed.preferred_username,
        phone: tokenParsed.phone_number,
        phoneNumber: tokenParsed.phone_number,
        email: tokenParsed.email,
      })
    }
    const tiviLink = await AdminVoterAuthService.getTiviElectionComponentLink()
    if (tiviLink && KeycloakAdminService?.isLoggedIn() && !KeycloakAdminService?.hasRole('tivi-contactinfo')) {
      setTiviLink(tiviLink)
    }
    // setSavedKeycloakLanguage()
    window.addEventListener('beforeunload', beforeUnload)
  }

  const clearLocalSession = () => {
    window.removeEventListener('beforeunload', beforeUnload)

    if (
      window.location.pathname.startsWith('/admin') &&
      !window.location.pathname.includes('login') &&
      !window.location.pathname.includes('reset-password')
    ) {
      history.push('/admin/login')
      setAdminUser({})
    }
  }

  const checkAuth = async () => {
    // is logged in
    if (initialized && KeycloakAdminService?.isLoggedIn()) {
      setLoggedInLocal()
    }
    if (initialized && !KeycloakAdminService?.isLoggedIn()) {
      clearLocalSession()
    }
  }

  useEffect(() => {
    checkAuth()
  }, [initialized, KeycloakAdminService?.isLoggedIn()])

  // set language saved in app
  const setSavedKeycloakLanguage = () => {
    if (KeycloakAdminService.isLoggedIn()) {
      AdminVoterService.updateUserLanguage(currentLanguage)
    }
  }
}
