import { STATE_STATUSES, getEventStateStatus } from '@nv/utils'
import CountdownTimerBig from './CountdownTimerBig'
import CountdownTimerSmall from './CountdownTimerSmall'
import FormattedDate from './FormattedDate'
import RenderTBD from './RenderTBD'

const CountdownTimer = (props) => {
  const { size } = props
  if (size === 'small') {
    return <CountdownTimerSmall {...props} />
  }
  return <CountdownTimerBig {...props} />
}

export const EventCountdownTimeDateContainer = ({ event, votingOpen, size = 'big', layout = null, hideFormattedDate = false }) => {
  const eventStateStatus = getEventStateStatus(event.state, votingOpen, event.nominationState)

  const diffNominationOpen = event.nominationOpenDate ? new Date().getTime() - new Date(event.nominationOpenDate).getTime() : 0
  const diffNominationClose = event.nominationCloseDate ? new Date().getTime() - new Date(event.nominationCloseDate).getTime() : 0
  const diffVotingOpen = new Date().getTime() - new Date(event.openDate).getTime()

  switch (eventStateStatus) {
    case STATE_STATUSES.nominationUpcoming:
      if (diffNominationOpen <= 0)
        return (
          <CountdownTimer size={size} countDownDate={event.nominationOpenDate} status={STATE_STATUSES.nominationUpcoming} layout={layout} />
        )
      return <RenderTBD status={STATE_STATUSES.nominationUpcoming} size={size} layout={layout} />

    case STATE_STATUSES.nominationStarted: {
      if (diffNominationClose <= 0)
        return (
          <CountdownTimer size={size} countDownDate={event.nominationCloseDate} status={STATE_STATUSES.nominationStarted} layout={layout} />
        )
      return <RenderTBD status={STATE_STATUSES.nominationStarted} size={size} layout={layout} />
    }
    case STATE_STATUSES.nominationClosed:
      if (hideFormattedDate) {
        return <div />
      }
      return <FormattedDate date={event.nominationCloseDate} status={STATE_STATUSES.nominationClosed} size={size} />
    case STATE_STATUSES.upcoming:
      if (diffVotingOpen <= 0)
        return <CountdownTimer size={size} countDownDate={event.openDate} status={STATE_STATUSES.upcoming} layout={layout} />
      return <RenderTBD status={STATE_STATUSES.upcoming} size={size} layout={layout} />
    case STATE_STATUSES.active:
      return <CountdownTimer size={size} countDownDate={event.closeDate} status={STATE_STATUSES.active} layout={layout} />
    case STATE_STATUSES.closed:
      if (hideFormattedDate) {
        return <div />
      }
      return <FormattedDate date={event.closeDate} status={STATE_STATUSES.closed} size={size} />
  }
}
export default EventCountdownTimeDateContainer
