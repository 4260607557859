import { useEffect, useState } from 'react'
import { useVoterUserContext } from '@nv/contexts'
import CircleArrowLeft from '@nv/images/design/circle-arrow-left-icon'
import CircleArrowRight from '@nv/images/design/circle-arrow-right-icon'
import XIcon from '@nv/images/design/x-icon'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import SideBarItem from './components/sidebar_item/SideBarItem'
import SideBarStyles from './SideBar.module.scss'

type SideBarProps = {
  children?: any
  onClose?: any
  sayHello?: boolean
  isMobile?: boolean
  links?: any
  logo?: any
  collapsedLogo?: any
  isCollapsed?: boolean
  onClickLogo?: any
}

export const SideBar = ({
  children,
  onClose,
  sayHello = false,
  isMobile = false,
  links,
  logo = undefined,
  collapsedLogo = undefined,
  isCollapsed: propIsCollapsed,
  onClickLogo = null,
}: SideBarProps) => {
  const { t } = useTranslation()
  const VoterUser = useVoterUserContext().voterUser

  // Use the passed prop if available
  const [isCollapsed, setIsCollapsed] = useState<boolean>(propIsCollapsed ?? JSON.parse(localStorage.getItem('isCollapsed') || 'false'))

  const toggleCollapse = () => {
    const newState = !isCollapsed
    localStorage.setItem('isCollapsed', JSON.stringify(newState))
    setIsCollapsed(newState)
  }

  useEffect(() => {
    if (propIsCollapsed !== undefined) {
      setIsCollapsed(propIsCollapsed)
    }
  }, [propIsCollapsed])

  return (
    <div
      className={clsx(SideBarStyles['sidebar'], isMobile ? SideBarStyles['sidebar__is-mobile'] : SideBarStyles['sidebar__is-not-mobile'], {
        [SideBarStyles['sidebar__collapsed']]: isCollapsed,
      })}
    >
      <div className={SideBarStyles['sidebar__header']} style={{ cursor: onClickLogo ? 'pointer' : null }}>
        {!isCollapsed ? (
          <span className={SideBarStyles['sidebar__logo']} onClick={() => onClickLogo && onClickLogo()}>
            {logo}
          </span>
        ) : (
          <span onClick={() => onClickLogo && onClickLogo()}>{collapsedLogo}</span>
        )}
        <XIcon onClick={() => onClose()} className={SideBarStyles['sidebar__x-icon']} />
      </div>

      {sayHello && !isCollapsed && (
        <span>
          {t('main.greeting', {
            name: `${VoterUser?.preferredName ? VoterUser?.preferredName : VoterUser?.firstName} ${VoterUser?.lastName}`,
          })}
        </span>
      )}
      <div className={SideBarStyles['sidebar__divider']}></div>
      {links &&
        Object.entries(links).map(([tabkey, tabValue]) => <SideBarItem key={tabkey} tabValue={tabValue} isCollapsed={isCollapsed} />)}
      {children}
      <button className={SideBarStyles['sidebar__collapse-btn']} onClick={toggleCollapse}>
        {isCollapsed ? <CircleArrowRight /> : <CircleArrowLeft />}
      </button>
    </div>
  )
}
