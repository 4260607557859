import BackArrowIcon from '@nv/images/design/back-arrow-icon'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import BackBtnStyles from './BackBtn.module.scss'

export const BackBtn = ({
  navigateTo = '',
  customStyling = {},
  onClick = null,
  label = undefined,
  children = undefined
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  const defaultOnClick = () => {
    navigateTo ? history.push(navigateTo) : history.goBack()
  }

  return (
    <span
      style={customStyling}
      className={BackBtnStyles['container']}
      onClick={onClick && !navigateTo ? onClick : defaultOnClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick && !navigateTo ? onClick() : defaultOnClick()
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className={BackBtnStyles['wrapper']}>
        <BackArrowIcon />
        <div className={BackBtnStyles['text']}>{label || t('buttons.back')} {children}</div>
      </div>
    </span>
  )
}
