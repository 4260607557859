import { SVG } from '../SVG'

const SearchIcon = ({ style={}, ...props }) => (
  <SVG
    width="40"
    height="45"
    viewBox="0 0 40 35"
    xmlns="http://www.w3.org/2000/svg"
    style={{"fill": "var(--color-black)", ...style}}
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8742 17.2621L20.1735 22.5613C20.7582 23.1461 21.7071 23.1461 22.2918 22.5613C22.8766 21.9766 22.8766 21.0278 22.2918 20.443L16.9926 15.1438C17.8552 13.8737 18.3594 12.3405 18.3594 10.6894C18.3594 6.30442 14.8046 2.75 10.4199 2.75C6.03526 2.75 2.48047 6.30479 2.48047 10.6894C2.48047 15.0741 6.03526 18.6289 10.4199 18.6289C12.071 18.6289 13.6042 18.1247 14.8742 17.2621ZM15.8012 10.6894C15.8012 13.6618 13.3918 16.0712 10.4195 16.0712C7.44724 16.0712 5.03784 13.6618 5.03784 10.6894C5.03784 7.71714 7.44761 5.30775 10.4199 5.30775C13.3922 5.30775 15.8016 7.71714 15.8016 10.6894H15.8012Z"
      />
    </g>
  </SVG>
)

export default SearchIcon
