import { SVG } from '../SVG'

const HandIcon = ({ style = {}, ...props }) => (
  <SVG
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    style={{ stroke:'var(--primary-color)', ...style }}
    {...props}
  >
    <path d='M20 8.125C20 7.2962 20.3292 6.50134 20.9153 5.91529C21.5013 5.32924 22.2962 5 23.125 5C23.9538 5 24.7487 5.32924 25.3347 5.91529C25.9208 6.50134 26.25 7.2962 26.25 8.125V17.5' />
    <path d='M13.75 10.625V5.625C13.75 4.7962 14.0792 4.00134 14.6653 3.41529C15.2513 2.82924 16.0462 2.5 16.875 2.5C17.7038 2.5 18.4987 2.82924 19.0847 3.41529C19.6708 4.00134 20 4.7962 20 5.625V16.25' />
    <path d='M20 26.875C20 25.2174 20.6585 23.6277 21.8306 22.4556C23.0027 21.2835 24.5924 20.625 26.25 20.625V17.5C26.25 16.6712 26.5792 15.8763 27.1653 15.2903C27.7513 14.7042 28.5462 14.375 29.375 14.375C30.2038 14.375 30.9987 14.7042 31.5847 15.2903C32.1708 15.8763 32.5 16.6712 32.5 17.5V23.75C32.5 27.0652 31.183 30.2446 28.8388 32.5888C26.4946 34.933 23.3152 36.25 20 36.25C16.6848 36.25 13.5054 34.933 11.1612 32.5888C8.81696 30.2446 7.5 27.0652 7.5 23.75V10.625C7.5 9.7962 7.82924 9.00134 8.41529 8.41529C9.00134 7.82924 9.7962 7.5 10.625 7.5C11.4538 7.5 12.2487 7.82924 12.8347 8.41529C13.4208 9.00134 13.75 9.7962 13.75 10.625V17.5' />
  </SVG>
)

export default HandIcon
