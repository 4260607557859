import { FormikError } from '@nv/basic-components'
import fr from 'date-fns/locale/fr'
import { registerLocale } from 'react-datepicker'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DatePickerStyles from './DatePicker.module.scss'
import clsx from 'clsx'

registerLocale('fr', fr)

export const DatePicker = ({ field, label = null, formik = null, isRequired = null, ...props }) => {
  const filterPastTime = (time) => {
    const now = new Date()
    const selectedTime = new Date(time)
    const isToday = new Date(formik.values[field]).toDateString() === now.toDateString()

    if (isToday) selectedTime.getTime() >= now.getTime()
    return true
  }

  return (
    <div className={DatePickerStyles['date-picker']}>
      {label && (
        <label htmlFor={field} className={clsx(DatePickerStyles['label'], isRequired && DatePickerStyles['required'])}>
          {label}
        </label>
      )}
      <ReactDatePicker
        selected={formik.values[field]}
        onChange={(date) => formik.setFieldValue(field, date)}
        className={props.className}
        locale={props.locale}
        maxDate={props.maxDate}
        placeholderText={'Select Date'}
        showTimeSelect
        dateFormat="Pp"
        filterTime={filterPastTime}
        {...props}
      />
      {formik && formik.touched[field] && formik.errors[field] && (
        <FormikError formik={formik} fieldName={field} errorLabel={formik.errors[field]} />
      )}
    </div>
  )
}
