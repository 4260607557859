import { CheckBox } from '@nv/basic-components'
import GroupCheckIcon from '@nv/images/design/group-check-icon'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ModalWrapper } from '../../ModalWrapper'
import AcceptNominationModalStyles from './AcceptNominationModal.module.scss'

export const AcceptNominationModal = ({ modalOpen, onClose, confirm, title }) => {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      consent: false,
    },
    validationSchema: Yup.object({
      consent: Yup.boolean().oneOf([true], t('You must agree to the terms')),
    }),
    onSubmit: () => {
      confirm()
    },
  })

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.confirm'),
      onClick: formik.handleSubmit,
    },
  ]

  return (
    <ModalWrapper isOpen={modalOpen} onRequestClose={onClose} headerLabel={t('Accept Nomination')} btns={btns}>
      <div className={AcceptNominationModalStyles['container']}>
        <GroupCheckIcon />
        <span className={AcceptNominationModalStyles['title']}>{t(`Youre accepting a nomination for ${title}.`)}</span>
        <div className={AcceptNominationModalStyles['consent']}>
          <CheckBox
            checked={formik.values.consent}
            onChange={() => formik.setFieldValue('consent', !formik.values.consent)}
            formik={formik}
            field="consent"
            label={t(
              `By accepting the nomination, I confirm that I comply with and will abide by QUT’s election and code of conduct requirements linked below.`,
            )}
          />
          <div>
            <br />
            <a
              href="https://qutvirtual4.qut.edu.au/group/staff/governance/council-and-committees/elections"
              target="_blank"
              rel="noopener noreferrer"
              className={AcceptNominationModalStyles['termsLink']}
            >
              Election and Code of Conduct Requirements for QUT staff
            </a>
            <br />
            <a
              href="https://qutvirtual4.qut.edu.au/group/student/student-life/get-involved/student-representatives"
              target="_blank"
              rel="noopener noreferrer"
              className={AcceptNominationModalStyles['termsLink']}
            >
              Election and Code of Conduct Requirements for QUT students
            </a>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}
