import { SVG } from '../SVG'

const NominationIcon = ({ style = {}, ...props }) => (
  <SVG
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: 'var(--primary-color)', ...style }}
    {...props}
  >
    <path d="M27.9998 20.8652C29.9506 20.8652 31.5321 19.2706 31.5321 17.3037C31.5321 15.3367 29.9506 13.7422 27.9998 13.7422C26.049 13.7422 24.4675 15.3367 24.4675 17.3037C24.4675 19.2706 26.049 20.8652 27.9998 20.8652Z" strokeWidth="1.75" strokeMiterlimit="10"/>
    <path d="M30.4851 27.0833H34.1669C34.1669 23.6495 31.4055 20.8652 27.9998 20.8652C24.5941 20.8652 21.8328 23.6495 21.8328 27.0833H25.8943" strokeWidth="1.75" strokeMiterlimit="10"/>
    <path d="M12.0301 20.8652C13.9809 20.8652 15.5623 19.2706 15.5623 17.3037C15.5623 15.3367 13.9809 13.7422 12.0301 13.7422C10.0793 13.7422 8.4978 15.3367 8.4978 17.3037C8.4978 19.2706 10.0793 20.8652 12.0301 20.8652Z" strokeWidth="1.75" strokeMiterlimit="10"/>
    <path d="M18.203 27.0833C18.203 23.6495 15.4416 20.8652 12.0359 20.8652C8.63017 20.8652 5.86304 23.6495 5.86304 27.0833H18.203Z" strokeWidth="1.75" strokeMiterlimit="10"/>
    <path d="M43.9585 20.8652C45.9094 20.8652 47.4908 19.2706 47.4908 17.3037C47.4908 15.3367 45.9094 13.7422 43.9585 13.7422C42.0077 13.7422 40.4263 15.3367 40.4263 17.3037C40.4263 19.2706 42.0077 20.8652 43.9585 20.8652Z" strokeWidth="1.75" strokeMiterlimit="10"/>
    <path d="M50.1314 27.0833C50.1314 23.6495 47.37 20.8652 43.9643 20.8652C40.5586 20.8652 37.7915 23.6495 37.7915 27.0833H50.1314Z" strokeWidth="1.75" strokeMiterlimit="10"/>
    <path d="M39.6726 50.4199H26.7286C25.3624 50.4199 24.2549 51.5366 24.2549 52.9141V54.6369C24.2549 56.0144 25.3624 57.1311 26.7286 57.1311H39.6726C41.0388 57.1311 42.1463 56.0144 42.1463 54.6369V52.9141C42.1463 51.5366 41.0388 50.4199 39.6726 50.4199Z" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M25.8944 42.1482V26.9278C25.8944 25.7619 26.8322 24.8164 27.9885 24.8164C29.1448 24.8164 30.0825 25.7619 30.0825 26.9278L30.2206 34.625C30.2206 33.4591 31.1066 32.5774 32.2571 32.4556C34.006 32.2642 34.4087 34.6714 34.4087 34.6714C34.4087 33.5055 35.3464 32.56 36.5027 32.56C37.6591 32.56 38.5968 33.5055 38.5968 34.6714L38.522 36.0635C38.522 35.0542 39.3332 34.2422 40.3284 34.2422C41.3237 34.2422 42.1348 35.06 42.1348 36.0635C42.1348 36.0635 42.4857 43.4765 42.1348 45.6575C41.7839 47.8384 40.4952 50.4313 40.4952 50.4313H25.9635L24.065 48.3605C24.065 48.3605 22.8512 46.98 22.3046 44.9846L21.3036 41.9626C21.3036 41.9626 20.8952 40.5009 18.9047 37.8326C18.9047 37.8326 17.9727 36.4695 18.7264 35.4835C19.0543 35.0542 20.2451 34.5438 21.2691 35.6749L25.8829 42.1424" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M38.1883 55.1115C38.9222 55.1115 39.5172 54.5116 39.5172 53.7715C39.5172 53.0315 38.9222 52.4316 38.1883 52.4316C37.4543 52.4316 36.8594 53.0315 36.8594 53.7715C36.8594 54.5116 37.4543 55.1115 38.1883 55.1115Z" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M24.2549 7.40162L26.8897 10.0582L33.028 3.86914" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
  </SVG>
)

export default NominationIcon
