import { SVG } from '../SVG'

export const EMSIcon = ({ inactive = false, style = {}, ...props }) => {
  return (
    <SVG width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style={{ ...style }} {...props}>
      <rect width="32" height="32" fill="white" />
      <path
        d="M19 6.4C19 6.18 18.82 6 18.6 6H17.4C17.18 6 17 6.18 17 6.4V8H19V6.4ZM15 6.4C15 6.18 14.82 6 14.6 6H13.4C13.18 6 13 6.18 13 6.4V8H15V6.4ZM23 6.4C23 6.18 22.82 6 22.6 6H21.4C21.18 6 21 6.18 21 6.4V8H23V6.4ZM11 6.4C11 6.18 10.82 6 10.6 6H9.4C9.18 6 9 6.18 9 6.4V8H11V6.4ZM6 9V25C6 25.55 6.45 26 7 26H25C25.55 26 26 25.55 26 25V9H6ZM24 24H8V14H24V24ZM13.6 23L16 21.4L18.4 23L18 20.2L20 18.2L17.2 17.8L16 15L14.8 17.8L12 18.2L14 20.2L13.6 23Z"
        fill="none"
        stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
        strokeWidth={1}
        strokeLinecap="round"
      />
    </SVG>
  )
}
