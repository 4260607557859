import { SVG } from '../SVG'

const PwEyeIcon = ({ style = {}, ...props }) => (
  <SVG
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ stroke: 'var(--svg-darker-fill)', ...style }}
    {...props}
  >
    <path d='M12 5.25C4.5 5.25 1.5 12 1.5 12C1.5 12 4.5 18.75 12 18.75C19.5 18.75 22.5 12 22.5 12C22.5 12 19.5 5.25 12 5.25Z' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
  </SVG>
)

export default PwEyeIcon
