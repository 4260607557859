import { SVG } from '../SVG'

const UploadIcon = ({ style = {}, ...props }) => (
  <SVG
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    fill='none'
    style={{ stroke: 'var(--primary-color)', ...style }}
    {...props}
  >
    <path fill={'var(--primary-color)'} opacity="0.25" d="M5 6.66601C5 3.90459 7.23858 1.66602 10 1.66602H26.2623C27.5883 1.66602 28.8601 2.1928 29.7978 3.13048L33.5355 6.86822C34.4732 7.8059 35 9.07767 35 10.4037V33.3327C35 36.0941 32.7614 38.3327 30 38.3327H10C7.23858 38.3327 5 36.0941 5 33.3327V6.66601Z"/>
    <path fill={'var(--primary-color)'} d="M25 3.15236C25 2.33147 25.6655 1.66602 26.4863 1.66602C27.669 1.66602 28.8031 2.13581 29.6394 2.97204L33.694 7.02666C34.5302 7.86289 35 8.99706 35 10.1797C35 11.0006 34.3345 11.666 33.5137 11.666H26.6667C25.7462 11.666 25 10.9198 25 9.99935V3.15236Z"/>
    <path fill={'var(--primary-color)'} d="M19.3627 15.1265C19.1661 15.2078 18.9819 15.3284 18.8221 15.4882L13.8221 20.4882C13.1713 21.139 13.1713 22.1943 13.8221 22.8452C14.473 23.4961 15.5283 23.4961 16.1792 22.8452L18.334 20.6904V28.3333C18.334 29.2538 19.0802 30 20.0007 30C20.9211 30 21.6673 29.2538 21.6673 28.3333V20.6904L23.8221 22.8452C24.473 23.4961 25.5283 23.4961 26.1792 22.8452C26.83 22.1943 26.83 21.139 26.1792 20.4882L21.1792 15.4882C20.6881 14.9971 19.9668 14.8765 19.3627 15.1265Z"/>
  </SVG>
)

export default UploadIcon
