import { useState } from 'react'
import { Select } from '@nv/basic-components'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import NominationStatusFilterModalStyles from './NominationStatusFilterModal.module.scss'

export const NominationStatusFilterModal = ({ isOpen, onRequestClose, filterOptions, propsSelectedOption, propsSetSelectedOption }) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState(propsSelectedOption)

  const onCancel = () => {
    setSelectedOption(null)
    onRequestClose()
  }

  const onApplyFilters = () => {
    console.log('Selected Option:', selectedOption)
    onRequestClose()
    propsSetSelectedOption(selectedOption)
  }

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onCancel,
      isCancel: true,
    },
    {
      text: t('buttons.apply'),
      onClick: onApplyFilters,
    },
  ]

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} headerLabel={t('filters.filterBy')} btns={btns}>
      <div className={NominationStatusFilterModalStyles['container']}>
        <Select
          title={t('Status')}
          options={filterOptions}
          field="filter"
          value={selectedOption}
          setValue={setSelectedOption}
          isRequired={true}
        />
      </div>
    </ModalWrapper>
  )
}
