import CircleCheckIcon from '@nv/images/design/circle-check-icon'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import UploadPDFSuccessModalStyles from './UploadPDFSuccessModal.module.scss'

export const UploadPDFSuccessModal = ({
  isOpen,
  onClick
}) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.ok'),
      onClick: onClick,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      headerLabel={t('PDF Upload Results')}
      btns={btns}
    >
      <div className={UploadPDFSuccessModalStyles['container']}>
        <CircleCheckIcon style={{ width: "200px", height: "100px" }} />
        <div className={UploadPDFSuccessModalStyles['description']}>{t('PDF Uploaded Successfully')}</div>
      </div>
    </ModalWrapper>
  )
}
