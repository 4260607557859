import { useEffect, useState } from 'react'
import { FormikError, Loader } from '@nv/basic-components'
import WarningIcon from '@nv/images/design/warning-icon'
import { AdminVoterAuthService } from '@nv/services'
import { useFormik } from 'formik'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ModalWrapper } from '../../ModalWrapper'
import DocumentInputUpload from './document_input_upload/DocumentInputUpload'
import EventAttachModalStyles from './EventAttachModal.module.scss'

const initialValues = {
  englishTitle: '',
  frenchTitle: '',
  englishDocument: null,
  frenchDocument: null,
}

const AddDocument = ({ formik, setUploadErr, setCurrentView }) => (
  <form>
    <div className={EventAttachModalStyles["container"]}>
      <DocumentInputUpload
        formik={formik}
        textFieldName="englishTitle"
        documentFieldName="englishDocument"
        label={t('uploads.document.labels.en')}
        setUploadErr={(error) => {
          setUploadErr(error)
          setCurrentView('uploadError')
        }}
      />
      {formik.errors['englishTitle'] && formik.errors['frenchTitle'] && (
        <FormikError formik={formik} fieldName="englishTitle" errorLabel={formik.errors['englishTitle']} />
      )}
      <DocumentInputUpload
        formik={formik}
        textFieldName="frenchTitle"
        documentFieldName="frenchDocument"
        label={t('uploads.document.labels.fr')}
        style={{ marginTop: 40 }}
        setUploadErr={(error) => {
          setUploadErr(error)
          setCurrentView('uploadError')
        }}
      />
      {formik.values['englishTitle'] && formik.errors['englishDocument'] && (
        <FormikError formik={formik} fieldName="englishDocument" errorLabel={formik.errors['englishDocument']} />
      )}
      {formik.values['frenchTitle'] && formik.errors['frenchDocument'] && (
        <FormikError formik={formik} fieldName="frenchDocument" errorLabel={formik.errors['frenchDocument']} />
      )}
    </div>
  </form>
)

export const EventAttachModal = ({
  view,
  modalOpen,
  onRequestClose = undefined,
  eventId,
  getAttachments
}) => {
  const { t } = useTranslation()
  const [currentView, setCurrentView] = useState(view)
  const [uploadErr, setUploadErr] = useState('')

  const onClose = () => {
    setCurrentView('Add Document')
    onRequestClose()
  }

  const validationSchema = Yup.object().shape({
    englishTitle: Yup.string().required(t('uploads.document.validations.title')),
    frenchTitle: Yup.string().required(t('uploads.document.validations.title')),
    englishDocument: Yup.mixed().required(t('uploads.document.validations.docReq')),
    frenchDocument: Yup.mixed().required(t('uploads.document.validations.docReq')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setCurrentView('loadingScreen')
        const formData = new FormData()
        formData.append('en-helpfile', values.englishDocument?.file)
        formData.set('en-title', values.englishTitle)
        formData.append('fr-helpfile', values.frenchDocument?.file)
        formData.set('fr-title', values.frenchTitle)
        if (eventId) {
          await AdminVoterAuthService.createHelpDocEvent(formData, eventId)
        } else {
          await AdminVoterAuthService.createHelpDoc(formData)
        }
        onClose()
        getAttachments()
      } catch (error) {
        setUploadErr(t('errors.contactSupport'))
        setCurrentView('uploadError')
      }
    },
  })

  useEffect(() => {
    if (
      (formik.values['englishTitle'] && formik.values['englishDocument']) ||
      (formik.values['frenchTitle'] && formik.values['frenchDocument'])
    ) {
      // Update validation schema if necessary
    }
  }, [formik.errors])

  const renderView = () => {
    if (currentView === 'Add Document') return <AddDocument formik={formik} setUploadErr={setUploadErr} setCurrentView={setCurrentView} />
    if (currentView === 'uploadError') return (
      <>
        <WarningIcon color={'var(--error-color)'} />
        <div>{uploadErr}</div>
      </>
    )
    if (currentView === 'loadingScreen') return <Loader />
  }

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.add'),
      onClick: formik.handleSubmit,
    },
  ]

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('uploads.document.add')}
      btns={btns}
    >
      {renderView()}
    </ModalWrapper>
  )
}

export default EventAttachModal
