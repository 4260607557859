import { SVG } from '../SVG'

const AudioIcon = ({ style = {}, ...props }) => (
  <SVG
    width="50"
    height="50"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    style={{"fill": "var(--primary-color)", ...style}}
    {...props}
  >
    <path d="M9.125 0C7.40625 0 6 1.40625 6 3.125V46.875C6 48.5938 7.40625 50 9.125 50H40.375C42.0938 50 43.5 48.5938 43.5 46.875V12.5L31 0H9.125Z" fill="var(--svg-light-fill)" />
    <path fill="none" d="M29.625 21C29.625 18.2386 27.3864 16 24.625 16C21.8636 16 19.625 18.2386 19.625 21V29C19.625 31.7614 21.8636 34 24.625 34C27.3864 34 29.625 31.7614 29.625 29V21Z" stroke="var(--primary-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24.625 38V42" stroke="var(--primary-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fill="none" d="M33.5758 30C33.3163 32.1915 32.2619 34.2116 30.6123 35.6774C28.9627 37.1433 26.8326 37.9529 24.6258 37.9529C22.419 37.9529 20.2889 37.1433 18.6393 35.6774C16.9897 34.2116 15.9352 32.1915 15.6758 30" stroke="var(--primary-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M34.125 12.5H43.5L31 0V9.375C31 11.0938 32.4062 12.5 34.125 12.5Z" fill="var(--svg-dark-fill)" />
    <path d="M43.5 21.875L34.125 12.5H43.5V21.875Z" fill="var(--svg-light-fill)" />
  </SVG>
)

export default AudioIcon
