import { SVG } from '../SVG'

const ElipsisIcon = ({ style = {}, ...props }) => (
  <SVG
    width="34"
    height="34"
    viewBox="0 0 34 34"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
    {...props}
  >
    <rect
      width="34"
      height="34"
      rx="6"
      fill="var(--color-background)"
      fillOpacity="0.1"
    />
    <circle
      cx="23.8577"
      cy="17.1429"
      r="2.14286"
      fill="var(--primary-color)"
    />
    <circle
      cx="17.0003"
      cy="17.1429"
      r="2.14286"
      fill="var(--primary-color)"
    />
    <circle
      cx="10.1429"
      cy="17.1429"
      r="2.14286"
      fill="var(--primary-color)"
    />
  </SVG>
)

export default ElipsisIcon
