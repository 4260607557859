export const Nominations = ({ inactive = false, style = {}, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} style={{ ...style }} {...props}>
      <defs>
        <clipPath id="a">
          <path d="M2.5 1.5h23V23h-23Zm0 0" />
        </clipPath>
        <clipPath id="b">
          <path d="M2.5 1.5h23V26h-23Zm0 0" />
        </clipPath>
        <clipPath id="c">
          <path d="M2.5 1.5H21V23H2.5Zm0 0" />
        </clipPath>
        <clipPath id="d">
          <path d="M2.5 1.5H22V26H2.5Zm0 0" />
        </clipPath>
        <clipPath id="e">
          <path d="M7 1.5h18.5V23H7Zm0 0" />
        </clipPath>
        <clipPath id="f">
          <path d="M6 1.5h19.5V26H6Zm0 0" />
        </clipPath>
        <clipPath id="g">
          <path d="M11 24h11v5H11Zm0 0" />
        </clipPath>
        <clipPath id="h">
          <path d="M11 1.5h6V6h-6Zm0 0" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          d="M28 20.867c1.953 0 3.531-1.594 3.531-3.562 0-1.97-1.578-3.563-3.531-3.563s-3.531 1.594-3.531 3.563c0 1.968 1.578 3.562 3.531 3.562Zm0 0"
          style={{
            fill: 'none',
            strokeWidth: 1.5,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',

            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          transform="scale(.5)"
          stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
        />
      </g>
      <g clipPath="url(#b)">
        <path
          d="M30.484 27.086h3.68c0-3.438-2.758-6.219-6.164-6.219-3.406 0-6.164 2.781-6.164 6.219h4.055"
          style={{
            fill: 'none',
            strokeWidth: 1.5,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',

            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          transform="scale(.5)"
          stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
        />
      </g>
      <g clipPath="url(#c)">
        <path
          d="M12.031 20.867c1.953 0 3.531-1.594 3.531-3.562 0-1.97-1.578-3.563-3.53-3.563-1.954 0-3.532 1.594-3.532 3.563 0 1.968 1.578 3.562 3.531 3.562Zm0 0"
          style={{
            fill: 'none',
            strokeWidth: 1.5,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',

            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          transform="scale(.5)"
          stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
        />
      </g>
      <g clipPath="url(#d)">
        <path
          d="M18.203 27.086c0-3.438-2.758-6.219-6.164-6.219-3.406 0-6.18 2.781-6.18 6.219Zm0 0"
          style={{
            fill: 'none',
            strokeWidth: 1.5,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',

            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          transform="scale(.5)"
          stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
        />
      </g>
      <g clipPath="url(#e)">
        <path
          d="M43.96 20.867c1.946 0 3.532-1.594 3.532-3.562 0-1.97-1.586-3.563-3.531-3.563-1.953 0-3.531 1.594-3.531 3.563 0 1.968 1.578 3.562 3.53 3.562Zm0 0"
          style={{
            fill: 'none',
            strokeWidth: 1.5,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',

            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          transform="scale(.5)"
          stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
        />
      </g>
      <g clipPath="url(#f)">
        <path
          d="M50.133 27.086c0-3.438-2.766-6.219-6.172-6.219-3.398 0-6.172 2.781-6.172 6.219Zm0 0"
          style={{
            fill: 'none',
            strokeWidth: 1.5,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',

            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          transform="scale(.5)"
          stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
        />
      </g>
      <g clipPath="url(#g)">
        <path
          d="M39.672 50.422H26.727c-1.368 0-2.47 1.117-2.47 2.492v1.727c0 1.375 1.102 2.492 2.47 2.492h12.945a2.486 2.486 0 0 0 2.476-2.492v-1.727a2.486 2.486 0 0 0-2.476-2.492Zm0 0"
          style={{
            fill: 'none',
            strokeWidth: 1.5,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',

            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          transform="scale(.5)"
          stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
        />
      </g>
      <path
        d="M25.89 42.148V26.93c0-1.164.946-2.117 2.102-2.117s2.094.953 2.094 2.117l.133 7.695c0-1.164.89-2.047 2.039-2.172 1.75-.187 2.148 2.219 2.148 2.219 0-1.164.938-2.11 2.094-2.11s2.094.946 2.094 2.11l-.07 1.39c0-1.007.812-1.82 1.804-1.82s1.805.82 1.805 1.82c0 0 .351 7.415 0 9.594-.352 2.18-1.64 4.774-1.64 4.774H25.96l-1.898-2.07s-1.211-1.383-1.758-3.376l-1-3.023s-.407-1.461-2.399-4.125c0 0-.93-1.367-.18-2.352.329-.43 1.516-.937 2.54.188l4.617 6.469M38.188 55.11c.734 0 1.328-.602 1.328-1.337 0-.742-.594-1.343-1.328-1.343-.735 0-1.329.601-1.329 1.343 0 .735.594 1.336 1.328 1.336Zm0 0"
        style={{
          fill: 'none',
          strokeWidth: 1.5,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',

          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        transform="scale(.5)"
        stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
      />
      <g clipPath="url(#h)">
        <path
          d="m24.258 7.398 2.633 2.657 6.14-6.188"
          style={{
            fill: 'none',
            strokeWidth: 1.5,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',

            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          transform="scale(.5)"
          stroke={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
        />
      </g>
    </svg>
  )
}
