import { SVG } from '../SVG'

const TableSortIcon = ({ style={}, ...props }) => (
  <SVG
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns='http://www.w3.org/2000/svg'
    style={{ "fill": "var(--primary-color)", ...style }}
    {...props}
  >
    <path
      d="M4.39043 4.51196C4.19027 4.76216 3.80973 4.76216 3.60957 4.51196L0.649878 0.812348C0.387973 0.484966 0.621059 0 1.04031 0H6.95969C7.37894 0 7.61203 0.484966 7.35012 0.812348L4.39043 4.51196Z"
      fill="var(--svg-darker-fill)"
    />
  </SVG>
)

export default TableSortIcon
