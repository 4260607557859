import { SVG } from '../SVG'

const DocIcon = ({ style = {}, ...props }) => (
  <SVG
    width="50"
    height="50"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    style={{"fill": "var(--color-black)", ...style}}
    {...props}
  >
    <path d="M12.5 0C10.7812 0 9.375 1.40625 9.375 3.125V46.875C9.375 48.5938 10.7812 50 12.5 50H43.75C45.4688 50 46.875 48.5938 46.875 46.875V12.5L34.375 0H12.5Z" fill="var(--svg-light-fill)" />
    <path d="M37.5 12.5H46.875L34.375 0V9.375C34.375 11.0938 35.7812 12.5 37.5 12.5Z" fill="var(--svg-darker-fill)" />
    <path d="M46.875 21.875L37.5 12.5H46.875V21.875Z" fill="var(--svg-dark-fill)" />
    <path d="M40.625 40.625C40.625 41.4844 39.9219 42.1875 39.0625 42.1875H4.6875C3.82812 42.1875 3.125 41.4844 3.125 40.625V25C3.125 24.1406 3.82812 23.4375 4.6875 23.4375H39.0625C39.9219 23.4375 40.625 24.1406 40.625 25V40.625Z" fill="#2AA9E0" />
    <path d="M39.0625 42.1875H9.375V43.75H39.0625C39.9219 43.75 40.625 43.0469 40.625 42.1875V40.625C40.625 41.4844 39.9219 42.1875 39.0625 42.1875Z" fill="var(--svg-dark-fill)" />
    <path d="M13.3124 37.0987H11.3151L11.3277 35.6244H13.3124C13.808 35.6244 14.2259 35.5131 14.5662 35.2904C14.9064 35.0636 15.1626 34.7339 15.3348 34.3013C15.5112 33.8687 15.5994 33.3457 15.5994 32.7325V32.2852C15.5994 31.8147 15.549 31.401 15.4482 31.044C15.3516 30.687 15.2067 30.3866 15.0135 30.143C14.8203 29.8994 14.583 29.7167 14.3015 29.5949C14.0201 29.4689 13.6967 29.4059 13.3313 29.4059H11.2773V27.9253H13.3313C13.9445 27.9253 14.5053 28.0303 15.0135 28.2403C15.5259 28.4461 15.969 28.7422 16.3429 29.1287C16.7167 29.5151 17.0044 29.9771 17.206 30.5147C17.4118 31.0482 17.5147 31.6425 17.5147 32.2978V32.7325C17.5147 33.3835 17.4118 33.9779 17.206 34.5155C17.0044 35.0531 16.7167 35.5152 16.3429 35.9016C15.9732 36.2838 15.5301 36.5799 15.0135 36.7899C14.501 36.9958 13.934 37.0987 13.3124 37.0987ZM12.3862 27.9253V37.0987H10.4961V27.9253H12.3862Z" fill="var(--color-white)" />
    <path d="M26.1654 32.2978V32.7325C26.1654 33.4297 26.0709 34.0556 25.8819 34.61C25.6929 35.1644 25.4262 35.637 25.0818 36.0276C24.7374 36.414 24.3257 36.7101 23.8469 36.916C23.3723 37.1218 22.8451 37.2247 22.2655 37.2247C21.6901 37.2247 21.1629 37.1218 20.6841 36.916C20.2095 36.7101 19.7978 36.414 19.4492 36.0276C19.1006 35.637 18.8297 35.1644 18.6365 34.61C18.4475 34.0556 18.3529 33.4297 18.3529 32.7325V32.2978C18.3529 31.5963 18.4475 30.9705 18.6365 30.4202C18.8255 29.8658 19.0922 29.3933 19.4366 29.0026C19.7852 28.612 20.1969 28.3138 20.6715 28.108C21.1503 27.9022 21.6775 27.7993 22.2529 27.7993C22.8325 27.7993 23.3597 27.9022 23.8343 28.108C24.3131 28.3138 24.7248 28.612 25.0692 29.0026C25.4178 29.3933 25.6866 29.8658 25.8756 30.4202C26.0688 30.9705 26.1654 31.5963 26.1654 32.2978ZM24.2564 32.7325V32.2852C24.2564 31.7979 24.2123 31.3695 24.1241 30.9999C24.0359 30.6303 23.9057 30.3194 23.7335 30.0674C23.5613 29.8154 23.3513 29.6264 23.1035 29.5004C22.8556 29.3702 22.5721 29.3051 22.2529 29.3051C21.9337 29.3051 21.6502 29.3702 21.4023 29.5004C21.1587 29.6264 20.9508 29.8154 20.7786 30.0674C20.6106 30.3194 20.4825 30.6303 20.3943 30.9999C20.3061 31.3695 20.262 31.7979 20.262 32.2852V32.7325C20.262 33.2155 20.3061 33.6439 20.3943 34.0178C20.4825 34.3874 20.6127 34.7003 20.7849 34.9565C20.9571 35.2085 21.1671 35.3997 21.4149 35.5299C21.6628 35.6601 21.9463 35.7252 22.2655 35.7252C22.5847 35.7252 22.8682 35.6601 23.1161 35.5299C23.3639 35.3997 23.5718 35.2085 23.7398 34.9565C23.9078 34.7003 24.0359 34.3874 24.1241 34.0178C24.2123 33.6439 24.2564 33.2155 24.2564 32.7325Z" fill="var(--color-white)" />
    <path d="M32.5858 34.0556H34.4696C34.4318 34.673 34.2617 35.2211 33.9593 35.7C33.6611 36.1788 33.2432 36.5526 32.7055 36.8214C32.1721 37.0903 31.5294 37.2247 30.7776 37.2247C30.1896 37.2247 29.6624 37.1239 29.1962 36.9223C28.73 36.7164 28.3309 36.4224 27.9991 36.0402C27.6715 35.658 27.4216 35.1959 27.2494 34.6541C27.0772 34.1123 26.9911 33.5053 26.9911 32.8333V32.197C26.9911 31.5249 27.0793 30.918 27.2557 30.3761C27.4363 29.8301 27.6925 29.366 28.0243 28.9837C28.3603 28.6015 28.7615 28.3075 29.2277 28.1017C29.6939 27.8959 30.2148 27.793 30.7902 27.793C31.5546 27.793 32.1994 27.9316 32.7244 28.2088C33.2537 28.486 33.6632 28.8682 33.953 29.3555C34.247 29.8427 34.4234 30.3971 34.4822 31.0188H32.5921C32.5711 30.6492 32.4976 30.3362 32.3716 30.08C32.2456 29.8196 32.0545 29.6243 31.7983 29.4941C31.5462 29.3597 31.2102 29.2925 30.7902 29.2925C30.4752 29.2925 30.2001 29.3513 29.9648 29.4689C29.7296 29.5865 29.5322 29.765 29.3726 30.0044C29.213 30.2438 29.0933 30.5462 29.0135 30.9117C28.9379 31.2729 28.9001 31.6971 28.9001 32.1844V32.8333C28.9001 33.3079 28.9358 33.7258 29.0072 34.0871C29.0786 34.4441 29.1878 34.7465 29.3348 34.9943C29.486 35.2379 29.6792 35.4228 29.9144 35.5488C30.1539 35.6706 30.4416 35.7315 30.7776 35.7315C31.1724 35.7315 31.4979 35.6685 31.7542 35.5425C32.0104 35.4165 32.2057 35.2295 32.3401 34.9817C32.4787 34.7339 32.5606 34.4252 32.5858 34.0556Z" fill="var(--color-white)" />
 </SVG>
)

export default DocIcon
