import { SVG } from '../SVG'

const CircleRightIcon = ({ style={}, ...props }) => (
  <SVG
    width="66"
    height="69"
    viewBox="0 0 66 69"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: "var(--primary-color)", ...style }}
    {...props}
  >
    <g clipPath="url(#clip0_9145_18224)">
      <path
        d="M61.7135 51.7106C61.3654 51.4588 60.9541 51.3257 60.5269 51.3257C60.0723 51.3257 59.6336 51.479 59.2812 51.7511L59.2294 51.6715L58.8641 52.1838C56.0678 56.1082 52.3394 59.3452 48.0846 61.5462C43.801 63.7617 39.1622 64.8846 34.296 64.8846C26.2265 64.8846 18.6418 61.7242 12.9371 55.9852C7.23233 50.2462 4.09084 42.6158 4.09084 34.4978C4.09084 26.3798 7.23233 18.7495 12.9371 13.0105C18.6418 7.27146 26.228 4.11109 34.296 4.11109C39.1449 4.11109 43.7694 5.22532 48.0415 7.4234C52.2848 9.60846 56.0059 12.8238 58.8036 16.7222C59.1863 17.2562 59.8048 17.576 60.4593 17.576C60.8879 17.576 61.2993 17.4428 61.6488 17.1896C62.0918 16.8698 62.3824 16.3952 62.4701 15.8554C62.5579 15.3156 62.4313 14.773 62.112 14.3273C58.9331 9.90221 54.7085 6.25418 49.8941 3.77392C45.028 1.27052 39.7807 0 34.2946 0C25.1333 0 16.5201 3.5887 10.0444 10.1033C3.56726 16.6223 0 25.2873 0 34.5007C0 43.7141 3.56726 52.382 10.043 58.8967C16.523 65.4128 25.1348 69 34.2931 69C39.8008 69 45.0697 67.7208 49.9502 65.1971C54.7818 62.6995 59.0122 59.0269 62.1853 54.5772C62.8398 53.6554 62.6269 52.3704 61.7135 51.7091V51.7106Z"
      />
      <path
        d="M65.7328 33.4354L65.7256 33.4209L65.6868 33.3602L65.6824 33.3515L65.6609 33.3182C65.5803 33.1966 65.4868 33.0838 65.3832 32.981L65.3113 32.9101L54.5362 22.0702C54.1507 21.681 53.6372 21.4668 53.092 21.4668C52.5469 21.4668 52.0334 21.681 51.6464 22.0688C51.2595 22.4566 51.0466 22.9717 51.0466 23.5202C51.0466 24.0701 51.2595 24.5867 51.6464 24.9759L59.0226 32.395H22.8135C21.6872 32.395 20.7695 33.3167 20.7695 34.4512C20.7695 35.5857 21.6858 36.5075 22.8135 36.5075H59.024L51.6479 43.928C51.2609 44.3158 51.0481 44.831 51.0481 45.3794C51.0481 45.9293 51.2609 46.4459 51.6464 46.8351C52.0319 47.2244 52.5454 47.4386 53.0906 47.4386C53.6358 47.4386 54.1493 47.2244 54.5362 46.8351L65.3832 35.9229C65.4811 35.8274 65.5731 35.716 65.6594 35.5901L65.6839 35.5524L65.7285 35.4801L65.7328 35.47C65.9083 35.1603 66.0003 34.8087 66.0003 34.4512C66.0003 34.0938 65.9083 33.7422 65.7328 33.4325V33.4354Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_9145_18224">
        <rect width="66" height="69" fill="white"/>
      </clipPath>
    </defs>
  </SVG>
)

export default CircleRightIcon
