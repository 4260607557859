import { useEffect, useMemo, useState } from 'react'
import { DownloadedModal, DownloadingModal, ListTable, NominationStatusFilterModal } from '@nv/components'
import AdminServiceQUT from 'apps/queensland/src/app/services/AdminServiceQUT'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const NUM_VOTERS_PER_PAGE = 50

export const NominationLogsTab = ({ contestName, event, eventId, qid }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const currentLanguage = i18n.language
  const [loadingCandidates, setLoadingCandidates] = useState(true)
  const [tableData, setTableData] = useState([])
  const [tableDataLength, setTableDataLength] = useState(0)
  const [currentPageNum, setCurrentPageNum] = useState(0)
  const [search, setSearch] = useState('')
  const [statusFilter, setStatusFilter] = useState('all')
  const [filterModal, setFilterModal] = useState(null)
  const filters = [
    { value: 'all', label: 'All' },
    { value: 'nominated', label: 'Nominated' },
    { value: 'nomination-withdrawn', label: 'Nominatation Removed' },
    { value: 'rejected', label: 'Nominatation Rejected' },
    { value: 'accepted', label: 'Accepted' },
    { value: 'withdrawn', label: 'Nominatation Withdrawn' },
    { value: 'admin-approved', label: 'Admin Approved' },
    { value: 'admin-declined', label: 'Admin Declined' },
  ]
  const [downloadingList, setDownloadingList] = useState(false)
  const [downloadURL, setDownloadURL] = useState(null)
  const [thanksModal, setThanksModal] = useState(false)

  const fetchCandidates = async () => {
    setLoadingCandidates(true)
    const candidates: any = await AdminServiceQUT.getNominationLogsPosition(
      eventId,
      qid,
      NUM_VOTERS_PER_PAGE,
      currentPageNum * NUM_VOTERS_PER_PAGE,
      search,
      statusFilter,
    )
    if (candidates) {
      setTableData(candidates.nominees)
      setTableDataLength(candidates.numNominees)
    }
    setLoadingCandidates(false)
  }

  useEffect(() => {
    fetchCandidates()
  }, [])

  useEffect(() => {
    setCurrentPageNum(0)
    fetchCandidates()
  }, [search, statusFilter])

  const getDowloadUrl = async () => {
    const res = await AdminServiceQUT.getNominationLogsCsvPosition(eventId, qid)
    if (res?.success) {
      const href = URL.createObjectURL(res.data)
      setDownloadURL(href)
      const link = document.createElement('a')
      link.download = `nominationlogs-${event.name[currentLanguage]}-${contestName}`
      link.href = href
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setDownloadingList(false)
  }

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'nominee.voterId',
        cell: (info) => info.getValue() || '',
        header: () => t('Nominee User Id'),
      },
      {
        accessorKey: 'nominee',
        cell: (info) =>
          `${info.getValue().firstName} ${info.getValue().preferredName ? `(${info.getValue().preferredName})` : ''} ${
            info.getValue().lastName
          }` || '',
        header: () => t('Nominee Name'),
      },
      {
        accessorKey: 'nominee.email',
        cell: (info) => info.getValue() || '',
        header: () => t('Nominee Email'),
      },
      {
        accessorKey: 'nominee.userType',
        cell: (info) => info.getValue() || '',
        header: () => t('Nominee Type'),
      },
      {
        accessorKey: 'nominator',
        cell: (info) =>
          `${info.getValue().firstName} ${info.getValue().preferredName ? `(${info.getValue().preferredName})` : ''} ${
            info.getValue().lastName
          }` || '',
        header: () => t('Nominator Name'),
      },
      {
        accessorKey: 'nominator.email',
        cell: (info) => info.getValue() || '',
        header: () => t('Nominator Email'),
      },
      {
        accessorKey: 'nominator.userType',
        cell: (info) => info.getValue() || '',
        header: () => t('Nominator Type'),
      },
      {
        accessorKey: 'nominationState',
        cell: (info) => info.getValue() || '',
        header: () => t('admin.status'),
      },
      {
        accessorKey: 'updatedAt',
        cell: (info) => info.getValue() || '',
        header: () => t('Last updated at'),
      },
    ]
  }, [currentLanguage])

  return (
    <>
      {downloadingList ? (
        <DownloadingModal
          isOpen={thanksModal}
          onRequestClose={() => {
            setThanksModal(false)
          }}
          headerLabel={t('admin.exports.candidates')}
          downloadURL={downloadURL}
        />
      ) : (
        <DownloadedModal
          isOpen={thanksModal}
          onRequestClose={() => {
            setThanksModal(false)
          }}
          headerLabel={t('admin.exports.candidates')}
          downloadURL={downloadURL}
        />
      )}

      <NominationStatusFilterModal
        isOpen={filterModal}
        onRequestClose={() => setFilterModal(false)}
        filterOptions={filters}
        propsSelectedOption={statusFilter}
        propsSetSelectedOption={setStatusFilter}
      />

      <ListTable
        data={tableData}
        columns={columns}
        currentPageNum={currentPageNum}
        dataLength={tableDataLength}
        setCurrentPageNum={setCurrentPageNum}
        numVotersPerPage={NUM_VOTERS_PER_PAGE}
        download={getDowloadUrl}
        setDownloadingList={setDownloadingList}
        search={search}
        setSearch={setSearch}
        setThanksModal={setThanksModal}
        loading={loadingCandidates}
        setFilterModal={setFilterModal}
        filter={true}
        listItemLabel="Nomination Logs"
      />
    </>
  )
}
