import React, { useState } from 'react'
import { Loader } from '@nv/basic-components'
import { SearchBar } from '@nv/basic-components'
import DownloadFileIcon from '@nv/images/design/download-file-icon'
import DropdownArrowIcon from '@nv/images/design/dropdown-arrow-icon'
import FunnelIcon from '@nv/images/design/funnel-icon'
import TableSortIcon from '@nv/images/design/table-sort-icon'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import TableFooter from './components/pagination/Pagination'
import ListTableStyles from './ListTable.module.scss'

export const ListTable = ({
  columns,
  data,
  currentPageNum = 0,
  dataLength = 0,
  loading = true,
  channelFilter = '',
  filter = false,
  search = '',
  setCurrentPageNum = undefined,
  download = undefined,
  setSearch = undefined,
  setFilterModal = undefined,
  setDownloadingList = undefined,
  setThanksModal = undefined,
  expandedContent = undefined,
  numVotersPerPage = 10,
  listItemLabel = '',
}) => {
  const [sorting, setSorting] = useState([])
  const [expandedRows, setExpandedRows] = useState([])
  const { t } = useTranslation()

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const toggleRowExpand = (rowId) => {
    setExpandedRows((prev) => {
      if (prev.includes(rowId)) {
        return prev.filter((id) => id !== rowId)
      } else {
        return [...prev, rowId]
      }
    })
  }

  return (
    <div className={ListTableStyles['table-container']}>
      <div className={ListTableStyles['actionbar']}>
        {setSearch && (
          <SearchBar
            placeholder={t('Search')}
            name="search"
            customStyling={{ width: 250, marginRight: 25 }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        )}
        <div>
          {(filter || channelFilter) && (
            <FunnelIcon
              onClick={() => {
                setFilterModal(true)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setFilterModal(true)
                }
              }}
              role="button"
              tabIndex={0}
            />
          )}
          {download && (
            <DownloadFileIcon
              onClick={() => {
                setDownloadingList(true)
                setThanksModal(true)
                download()
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setDownloadingList(true)
                  setThanksModal(true)
                  download()
                }
              }}
              role="button"
              tabIndex={0}
            />
          )}
        </div>
      </div>

      {loading ? (
        <div className="spinner-container">
          <Loader />
        </div>
      ) : (
        <table className={ListTableStyles['table']}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} onClick={header.column.getToggleSortingHandler()} className={ListTableStyles['sortable-header']}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

                    {header.column.getIsSorted() === 'asc' && <TableSortIcon className={ListTableStyles['sort-asc']} />}
                    {header.column.getIsSorted() === 'desc' && <TableSortIcon className={ListTableStyles['sort-desc']} />}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <React.Fragment key={row.id}>
                <tr className={ListTableStyles['tr']}>
                  {row.getVisibleCells().map((cell, index) =>
                    cell.getContext().column.id !== 'expand' ? (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ) : (
                      <td key={`expand-btn-${row.id}`}>
                        <button className={ListTableStyles['expand-btn']} onClick={() => toggleRowExpand(row.id)}>
                          {expandedRows.includes(row.id) ? (
                            <DropdownArrowIcon className={ListTableStyles['expand-btn__expanded']} />
                          ) : (
                            <DropdownArrowIcon />
                          )}
                        </button>
                      </td>
                    ),
                  )}
                </tr>

                {expandedRows.includes(row.id) && (
                  <tr>
                    <td colSpan={row.getVisibleCells().length + 1} className={ListTableStyles['expanded__content']}>
                      {expandedContent(row)}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>

          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      )}
      {dataLength !== 0 && (
        <TableFooter
          currentPageNum={currentPageNum}
          dataLength={dataLength}
          setCurrentPageNum={setCurrentPageNum}
          numVotersPerPage={numVotersPerPage}
          listItemLabel={listItemLabel}
        />
      )}
    </div>
  )
}
