import { SVG } from '../SVG'

const BalletBoxIcon = ({ style = {}, ...props }) => (
  <SVG
    width="55"
    height="55"
    viewBox="0 0 55 55"
    xmlns="http://www.w3.org/2000/svg"
    fill='none'
    style={{ stroke: 'var(--primary-color)', ...style }}
    {...props}
  >
    <path
      d="M19.4792 23.3105V5.15625H34.375V23.3105M19.4792 15.3971H9.47222L6.875 31.6895M34.5787 15.3971H44.662L48.125 31.6895M17.2639 23.3105H36.7431M6.875 31.6895V49.8438H48.125V31.6895M6.875 31.6895H48.125"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)

export default BalletBoxIcon
