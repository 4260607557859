import { SVG } from '../SVG'

const WarningIcon = ({ color='var(--primary-color)', style = {}, ...props }) => (
  <SVG
    width="65"
    height="65"
    viewBox="0 0 65 65"
    xmlns="http://www.w3.org/2000/svg"
    fill='none'
    style={{ stroke: color, ...style }}
    {...props}
  >
    <path d="M32.5 26.4062V36.5625" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M28.9955 10.1569L6.65171 48.7506C6.29575 49.3672 6.10805 50.0664 6.10742 50.7783C6.10679 51.4902 6.29325 52.1898 6.64812 52.807C7.00298 53.4241 7.51379 53.9372 8.12938 54.2948C8.74497 54.6523 9.44371 54.8419 10.1556 54.8444H54.8431C55.555 54.8419 56.2538 54.6523 56.8694 54.2948C57.4849 53.9372 57.9957 53.4241 58.3506 52.807C58.7055 52.1898 58.8919 51.4902 58.8913 50.7783C58.8907 50.0664 58.703 49.3672 58.347 48.7506L36.0033 10.1569C35.65 9.53956 35.1399 9.02653 34.5246 8.66972C33.9093 8.31292 33.2106 8.125 32.4994 8.125C31.7881 8.125 31.0895 8.31292 30.4742 8.66972C29.8589 9.02653 29.3487 9.53956 28.9955 10.1569V10.1569Z" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    <path fill={color} d="M32.5 48.75C34.1827 48.75 35.5469 47.3859 35.5469 45.7031C35.5469 44.0204 34.1827 42.6562 32.5 42.6562C30.8173 42.6562 29.4531 44.0204 29.4531 45.7031C29.4531 47.3859 30.8173 48.75 32.5 48.75Z" />
  </SVG>
)

export default WarningIcon
