import { KeycloakAdminService } from '@nv/auth'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const VERIFY_COOKIE = 'verifyTokenAdmin'
const { NX_PUBLIC_PUBLIC_URL } = process.env

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {},
  baseURL: `${process.env.NX_PUBLIC_PUBLIC_URL}/admin`,
})

// attach access token
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = KeycloakAdminService.getToken()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

// flattens the layer of nested introduced by axios
// the responses look like { data, error }, but axios nests the whole response under 'data'
axiosInstance.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(VERIFY_COOKIE, res.accessToken)
    }

    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
      cookies.remove(VERIFY_COOKIE)
    }
    return res
  },
  async (err) => {
    //console.log(err)
    const error = {
      ...err.response?.data?.error,
      ...err,
    }
    return Promise.reject(error)
  },
)

const axiosInstanceVerify = axios.create({
  withCredentials: true,
  headers: {},
  baseURL: `${process.env.NX_PUBLIC_PUBLIC_URL}/admin`,
})

// attach access token
axiosInstanceVerify.interceptors.request.use(
  async (config) => {
    const accessToken = cookies.get(VERIFY_COOKIE)
    //console.log('access token', accessToken)
    if (sessionStorage.getItem('redirect') === 'true') {
      config.params = { ...config.params, redirect: 'true' }
    }
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)
axiosInstanceVerify.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(VERIFY_COOKIE, res.accessToken)
    }
    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
      cookies.remove(VERIFY_COOKIE)
    }
    return res
  },
  async (err) => {
    if (err.status === 403 || err.response?.status === 403) {
      cookies.remove(VERIFY_COOKIE)
    }
    const error = {
      ...err.response?.data?.error,
      ...err,
    }
    return Promise.reject(error)
  },
)

export const AdminVoterAuthService = {
  // login with username and password then go to next step
  login: async (username, password, recaptchaToken, language) => {
    try {
      const config = {}
      if (sessionStorage.getItem('redirect') === 'true') {
        config.params = { ...config.params, redirect: 'true' }
      }
      let resp = await axios.post(
        `${NX_PUBLIC_PUBLIC_URL}/admin/auth/login?locale=${language}`,
        {
          username: username,
          password: password,
          recaptchaToken,
        },
        config,
      )
      let user
      resp = resp.data

      if (resp.accessToken) {
        cookies.set(VERIFY_COOKIE, resp.accessToken)
      }
      if (resp.user) {
        user = resp.user
      }
      if (resp.link && resp.link.includes('login-actions')) {
        window.location.replace(resp.link)
        cookies.remove(VERIFY_COOKIE)
      }

      return {
        success: true,
        user: user,
        link: resp.link,
        passwordResetRequired: resp.passwordResetRequired,
        onboarding: resp.onboarding,
      }
    } catch (error) {
      //console.log(error.response?.data?.error)
      const errorDetails = {
        ...error.response?.data?.error,
      }
      return { error: errorDetails }
    }
  },
  verifyAuthAppCode: async (code) => {
    try {
      await axiosInstanceVerify.post(`${NX_PUBLIC_PUBLIC_URL}/admin/auth/mfa/verify/authapp`, { code })

      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  verifySMSCodeOnboard: async (verificationCode) => {
    try {
      const resp = await axiosInstanceVerify.post('/onboard/mfa/verify/sms', { verificationCode })

      return { success: true, qrCodeDataUrl: resp.qrCodeDataUrl, factorSid: resp.factorSid }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  verifyAuthAppCodeOnboard: async (code, factorSid) => {
    try {
      const resp = await axiosInstanceVerify.post('/onboard/mfa/verify/authapp', {
        code,
        factorSid,
      })
      return { success: true, passwordResetRequired: resp.link ? false : true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  updateUserPasswordOnboard: async (currentPassword, newPassword) => {
    try {
      await axiosInstanceVerify.put('/onboard/password', {
        currentPassword,
        newPassword,
      })
      return { success: true }
    } catch (error) {
      //console.log(error)
    }
  },
  requestRegenerateQRCode: async () => {
    try {
      await axiosInstance.post('/auth/request/regenerate-qr-code')
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  requestPasswordReset: async (username, email, recaptchaToken) => {
    try {
      await axiosInstanceVerify.post('/auth/request/resetpassword', {
        username,
        email,
        recaptchaToken,
      })
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  verifyAuthAppCodePasswordResetFlow: async (code) => {
    try {
      await axiosInstanceVerify.post('/auth/resetpassword/mfa/verify/authapp', { code })

      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  updateUserPasswordResetPasswordFlow: async (currentPassword, newPassword) => {
    try {
      await axiosInstanceVerify.put('/auth/resetpassword/password', {
        currentPassword,
        newPassword,
      })
      return { success: true }
    } catch (error) {
      //console.log(error)
    }
  },
  getUserInfoKeycloakToken: () => {
    try {
      const tokenParsed = KeycloakAdminService.getParsedToken()
      let user = {}
      if (tokenParsed) {
        user = {
          username: tokenParsed.preferred_username,
          email: tokenParsed.email,
          phone: tokenParsed.phone_number,
          phoneNumber: tokenParsed.phone_number,
          // TODO role
        }
      }
      return user
    } catch (error) {
      /* empty */
    }
  },
  updateUserPassword: async (currentPassword, newPassword) => {
    try {
      await axiosInstance.put('/auth/password', { currentPassword, newPassword })
      return { success: true }
    } catch (error) {
      //console.log(error)
    }
  },
  adminUnlockAccountFoAdminUser: async (keycloakId) => {
    try {
      await axiosInstance.post(`/auth/users/${keycloakId}/unlock`)
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  adminResetPasswordForAdminUser: async (keycloakId) => {
    try {
      await axiosInstance.post(`/auth/users/${keycloakId}/resetpassword`)
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  adminRegenerateQRCodeForAdminUser: async (keycloakId) => {
    try {
      await axiosInstance.post(`/auth/users/${keycloakId}/regenerate-qr-code`)
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  adminFetchAdminUsers: async () => {
    try {
      const resp = await axiosInstance.get(`/admin-users`)
      return { success: true, data: resp }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  adminFetchRealmRoles: async () => {
    try {
      const resp = await axiosInstance.get(`/admin-roles`)
      return { success: true, data: resp }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  adminCreateUser: async (userData) => {
    try {
      await axiosInstance.post('/admin-users', userData)
      return { success: true }
    } catch (error) {
      return { error: 'An error occurred while creating the user' }
    }
  },
  adminDeleteUser: async (keycloakId) => {
    try {
      await axiosInstance.delete(`/admin-users/${keycloakId}`)
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  getTiviElectionComponentLink: async () => {
    try {
      const resp = await axiosInstance.get(`/election-component-link`)
      return resp.tiviLink
    } catch (error) {
      //console.log(error)
      // return {error}
    }
  },
  extendSession: async () => {
    try {
      const resp = await axiosInstance.get('/auth/extend-session')
      if (resp.link) {
        window.location.location.href = resp.link
      }
    } catch (error) {
      //console.log(error)
      return { error, success: false }
    }
  },
  logOut: async (history) => {
    if (KeycloakAdminService.isLoggedIn()) {
      // try {
      //   await axiosInstance.get('/auth/logout')
      // } catch (error) {
      //   //console.error('admin logout', error)
      // }

      KeycloakAdminService.doLogout({ redirectUri: `${window.origin}/admin/login` })
    }
    sessionStorage.clear()
    history && history.push('/admin/login')
    if (!history) {
      window.location.reload()
    }
  },
  getHelpDocs: async () => {
    try {
      const resp = await axiosInstance.get(`/helpguide`)

      return resp
    } catch (error) {
      //console.log(error)
    }
  },
  getHelpDocsEvent: async (eventId) => {
    try {
      const resp = await axiosInstance.get(`/helpguide/events/${eventId}`)

      return resp
    } catch (error) {
      //console.log(error)
    }
  },
  createHelpDoc: async (document) => {
    try {
      //console.log(document)
      const resp = await axiosInstance.post(`/helpguide`, document)

      return resp
    } catch (error) {
      //console.log(error)
    }
  },
  createHelpDocEvent: async (document, eventId) => {
    try {
      const resp = await axiosInstance.post(`/helpguide/events/${eventId}`, document)

      return resp
    } catch (error) {
      //console.log(error)
    }
  },
  deleteHelpDoc: async (documentId) => {
    try {
      await axiosInstance.delete(`/helpguide/${documentId}`)
      //console.log('delete success')
    } catch (error) {
      //console.log('delete errorr', error)
    }
  },
  getVoterDetails: async ({ voterId }) => {
    try {
      const response = await axiosInstance.get(`/voters/${voterId}`)

      return response
    } catch (error) {
      //console.log(error)
    }
  },
  getRegistrationState: async () => {
    try {
      const response = await axiosInstance.get(`/settings`)

      return response
    } catch (error) {
      //console.log(error)
    }
  },
  updateRegistrationState: async (registrationState) => {
    try {
      const response = await axiosInstance.put(`/settings/registration-state/${registrationState}`)

      return response
    } catch (error) {
      //console.log(error)
    }
  },
  getEventById: async (eventId) => {
    try {
      if (eventId) {
        const event = await axiosInstance.get(`/events/${eventId}`)
        return event
      }
    } catch (error) {
      //console.log(error)
    }
  },
}

export const voterAPI = axiosInstance
