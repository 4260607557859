import HamiltonBanner from '@nv/images/banners/hamilton-banner.png'
import PEIBanner from '@nv/images/banners/pei-banner.png'
import PortalBanner from '@nv/images/banners/portal-banner.png'
import QueenslandBanner from '@nv/images/banners/queensland-banner.png'
import BackgroundBannerStyles from './BackgroundBanner.module.scss'

export const BackgroundBanner = ({ banner = "pipsc" }) => {
  const banners = {
    pipsc: PortalBanner,
    queensland: QueenslandBanner,
    hamilton: HamiltonBanner,
    pei: PEIBanner,
  }

  return <div className={BackgroundBannerStyles.banner} style={{ backgroundImage: `url(${banners[banner]})` }}></div>
}
