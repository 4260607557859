import CircleCheckIcon from '@nv/images/design/circle-check-icon'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import WithdrawCandicacySuccessStyles from './WithdrawCandicacySuccessModal.module.scss'

export const WithdrawCandicacySuccessModal = ({ modalOpen, onClose, confirm, title }) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.close'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.ok'),
      onClick: confirm,
    },
  ]

  return (
    <ModalWrapper isOpen={modalOpen} onRequestClose={onClose} headerLabel={t('Withdraw Candidacy')} btns={btns}>
      <div className={WithdrawCandicacySuccessStyles['container']}>
        <CircleCheckIcon className={WithdrawCandicacySuccessStyles['svg']} />
        <span className={WithdrawCandicacySuccessStyles['title']}>{t(`You have successfully withdrawn your candidacy for ${title}`)}</span>
      </div>
    </ModalWrapper>
  )
}
