import { SVG } from '../SVG'

const VideoIcon = ({ style = {}, ...props }) => (
  <SVG
    width="50"
    height="50"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    style={{"fill": "var(--primary-color)", ...style}}
    {...props}
  >
    <path d="M3.81812 0C2.09937 0 0.693115 1.40625 0.693115 3.125V46.875C0.693115 48.5938 2.09937 50 3.81812 50H35.0681C36.7869 50 38.1931 48.5938 38.1931 46.875V12.5L25.6931 0H3.81812Z" fill="var(--svg-light-fill)" />
    <path d="M28.5 12.5H37.875L25.375 0V9.375C25.375 11.0938 26.7812 12.5 28.5 12.5Z" fill="var(--svg-dark-fill)" />
    <path d="M16.1399 32.1781L22.4961 25.8125" stroke="var(--primary-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fill="none" d="M20.9118 33.7719L18.2587 36.425C17.8407 36.843 17.3445 37.1745 16.7984 37.4007C16.2523 37.6269 15.667 37.7434 15.0759 37.7434C13.8821 37.7434 12.7372 37.2691 11.8931 36.425C11.0489 35.5809 10.5747 34.436 10.5747 33.2422C10.5747 32.0484 11.0489 30.9035 11.8931 30.0594L14.5462 27.4062" stroke="var(--primary-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fill="none" d="M24.09 30.594L26.7431 27.9408C27.5872 27.0967 28.0615 25.9518 28.0615 24.758C28.0615 23.5642 27.5872 22.4193 26.7431 21.5752C25.899 20.7311 24.7541 20.2568 23.5603 20.2568C22.3665 20.2568 21.2216 20.7311 20.3775 21.5752L17.7244 24.2283" stroke="var(--primary-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SVG>
)

export default VideoIcon
