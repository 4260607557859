import WarningIcon from '@nv/images/design/warning-icon'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import ReminderConfirmModalStyles from './ReminderConfirmModal.module.scss'

export const ReminderConfirmModal = ({ isOpen, onClose, onSend }) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.send'),
      onClick: onSend,
    },
  ]

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onClose} headerLabel={'Email Reminders'} btns={btns}>
      <div className={ReminderConfirmModalStyles['container']}>
        <WarningIcon className={ReminderConfirmModalStyles['warning-icon']} color={'var(--error-color)'} />
        <span className={ReminderConfirmModalStyles['title']}>Are you sure you want to send a reminder email to nominators?</span>
      </div>
    </ModalWrapper>
  )
}
