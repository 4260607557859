import Modal from 'react-modal'
import LanguagesDropdown from '../../languages_dropdown/LanguagesDropdown'
import { SideBarFooter } from './components/sidebar_footer/SideBarFooter'
import { SideBar } from './SideBar'
import SideBarStyles from './SideBar.module.scss'

const customStyles = {
  content: {
    borderRadius: '4px',
    border: 'none',
    width: 299,
    height: '100%',
    top: 0,
    left: 0,
    padding: 0,
    maxWidth: 400,
  },

  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99999999,
  },
}

export const SidebarMobile = ({
  sideNavbarOpen,
  setSideNavbarOpen,
  links,
  logo,
  hasLanguage = true,
  showCurrentTime = true
}) => {

  return (
    <Modal
      ariaHideApp={false}
      isOpen={sideNavbarOpen}
      onRequestClose={() => setSideNavbarOpen(false)}
      style={customStyles}
      contentLabel='SideBar Modal'
    >
      <SideBar logo={logo} links={links} onClose={() => setSideNavbarOpen(false)} isMobile={true} isCollapsed={false}>
        <div className={SideBarStyles['dropdown-languages']}>
          {hasLanguage && <LanguagesDropdown />}
        </div>
        <SideBarFooter showCurrentTime={showCurrentTime} />
      </SideBar>
    </Modal>
  )
}
