export const STATE_STATUSES = {
  active: 'active',
  upcoming: 'upcoming',
  closed: 'closed',
  nominationUpcoming: 'nominationUpcoming',
  nominationStarted: 'nominationStarted',
  nominationClosed: 'nominationClosed',
}

export const getEventStateStatus = (eventState, votingOpen, nominationState = 'disabled') => {
  if (votingOpen == null) {
    return
  }
  if (votingOpen) {
    return STATE_STATUSES.active
  } else if (eventState === 'started' && !votingOpen) {
    return STATE_STATUSES.closed
  } else if (['created'].includes(eventState)) {
    // nominations are open when voting is upcoming
    if (['enabled', 'upcoming'].includes(nominationState)) {
      return STATE_STATUSES.nominationUpcoming
    } else if (nominationState === 'started') {
      return STATE_STATUSES.nominationStarted
    } else if (['ended'].includes(nominationState)) {
      return STATE_STATUSES.nominationClosed
    }
    if (['disabled', 'sealed'].includes(nominationState)) {
      return STATE_STATUSES.upcoming
    }
  } else if (['finalized'].includes(eventState)) {
    return STATE_STATUSES.upcoming
  } else if (['ended', 'decrypting', 'decrypted', 'tallied'].includes(eventState)) {
    return STATE_STATUSES.closed
  }
}
