import { useMemo } from 'react'
import DividerIcon from '@nv/images/design/divider-icon'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import LanguagesDropdown from '../../languages_dropdown/LanguagesDropdown'
import LogoutButton from '../../logout_btn/LogoutBtn'
import { Hamburger } from './components/hamburger/Hamburger'
import { SessionTimer } from './components/session_timer/SessionTimer'
import WelcomeMessage from './components/welcome_message/WelcomeMessage'
import TopBarStyles from './TopBar.module.scss'

export function TopBar({
  noAuthentication = false,
  logo = undefined,
  links = undefined,
  className = undefined,
  mobileLogo = undefined,
  disableSessionExtension = false,
  hasLanguage = undefined,
  showCurrentTime = undefined,
}) {
  const renderLanguagesDropdown = useMemo(() => <LanguagesDropdown />, [])
  const history = useHistory()

  return (
    <div className={clsx(TopBarStyles['container'], TopBarStyles[className])}>
      <span
        className={TopBarStyles['logo']}
        onClick={() => history.push('/')}
        onKeyDown={(e) => {
          if (e.key === 'Enter') history.push('/')
        }}
        role="button"
        tabIndex={0}
      >
        <div className={TopBarStyles['mobile-logo']}>{mobileLogo}</div>
      </span>
      {!noAuthentication ? <SessionTimer disableSessionExtension={disableSessionExtension} showCurrentTime={showCurrentTime} /> : <div style={{ margin: 'auto' }} />}
      <div className={TopBarStyles['btn-container']}>
        {hasLanguage && renderLanguagesDropdown}
        {!noAuthentication && (
          <>
            <DividerIcon style={{ marginRight: '1.25rem' }} />
            <WelcomeMessage />
            <div className={TopBarStyles['divider-container']}>
              <DividerIcon style={{ marginRight: '1.25rem', marginLeft: '1.25rem' }} />
            </div>
            <LogoutButton />
          </>
        )}
      </div>
      <div className={TopBarStyles['hamburger']}>
        <Hamburger links={links} logo={logo} hasLanguage={hasLanguage} showCurrentTime={showCurrentTime} />
      </div>
    </div>
  )
}
