import { createContext, useContext, useState } from 'react'

export const VoterUserContext = createContext()

export const VoterUserProvider = ({ children }) => {
  const [voterUser, setVoterUser] = useState(null)

  const [initialized, setInitialized] = useState(false)

  return (
    <VoterUserContext.Provider
      value={{
        voterUser,
        setVoterUser,
        initialized,
        voterKcInitialized: initialized,
        setInitialized,
      }}
    >
      {children}
    </VoterUserContext.Provider>
  )
}

export const useVoterUserContext = () => {
  const PROVIDER_ERROR = 'useVoterUserContext must be used within an VoterUserProvider'
  const context = useContext(VoterUserContext)
  if (!context) {
    throw new Error(PROVIDER_ERROR)
  }
  return context
}

export default VoterUserContext
