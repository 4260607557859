import { useContext } from 'react'
import { KeycloakService } from '@nv/auth'
import { KeycloakAdminService } from '@nv/auth'
import { LoginFlowContext } from '@nv/contexts'
import { useCurrentTime } from '@nv/hooks'
import { AdminVoterAuthService } from '@nv/services'
import { VoterAuthService } from '@nv/services'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import SideBarFooterStyles from './SideBarFooter.module.scss'

export const SideBarFooter = ({ showCurrentTime = true }) => {
  const history = useHistory()
  const currentTime = useCurrentTime()
  const LOGIN_CONTEXT = useContext(LoginFlowContext)
  const { t } = useTranslation()

  return (
    <>
      <div className={SideBarFooterStyles['container']}>
        {showCurrentTime &&
          <span className={SideBarFooterStyles['time']}>
            <span>{t("main.currentTime")}</span>
            <span> {currentTime}</span>
          </span>
        }

        <button
          className={SideBarFooterStyles['logout-btn']}
          onClick={async () => {
            try {
              if (KeycloakService.isLoggedIn()) {
                LOGIN_CONTEXT.setLoginFlow('Login')
                await VoterAuthService.logOut(history)
              }
              if (KeycloakAdminService.isLoggedIn()) {
                LOGIN_CONTEXT.setLoginFlow('Admin Login')
                await AdminVoterAuthService.logOut(history)
              }
            } catch (error) {
              //console.log(error)
            }
          }}
        >
          {t('main.logout')}
        </button>
      </div>
    </>
  )
}
