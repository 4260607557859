import WithdrawIcon from '@nv/images/design/withdraw-icon'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import RejectNominationModalStyles from './RejectNominationModal.module.scss'

export const RejectNominationModal = ({ modalOpen, onClose, confirm, title }) => {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      consent: false,
    },
    onSubmit: () => {
      confirm()
    },
  })

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.confirm'),
      onClick: formik.handleSubmit,
    },
  ]

  return (
    <ModalWrapper isOpen={modalOpen} onRequestClose={onClose} headerLabel={t('Reject Nomination')} btns={btns}>
      <div className={RejectNominationModalStyles['container']}>
        <WithdrawIcon />
        <span className={RejectNominationModalStyles['title']}>
          {t(`You're rejecting a nomination for ${title}. This action is irreversible.`)}
        </span>
      </div>
    </ModalWrapper>
  )
}
