import Keycloak from 'keycloak-js'

const { NX_PUBLIC_KEYCLOAK_URL } = process.env
const keycloakConfig = {
  url: NX_PUBLIC_KEYCLOAK_URL,
  clientId: 'voter-registration-react',
  realm: 'voter-registration',
}

const _kc = new Keycloak(keycloakConfig)

const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      // console.log(authenticated, _kc)
      if (!authenticated) {
        // console.log('user is not authenticated..!')
      } else {
        // console.log('user is authenticated.')
      }
      onAuthenticatedCallback()
    })
    .catch(console.error)
}

const doLogin = _kc.login
const doLogout = _kc.logout
const updateToken = (timeToExpiry) => _kc.updateToken(timeToExpiry)

const onTokenExpired = async (onSessionExpired, timeToExpiry) => {
  try {
    await _kc.updateToken(timeToExpiry)
  } catch (error) {
    onSessionExpired()
  }
}

const getToken = () => _kc.token
const isLoggedIn = () => !!_kc.token

const getUsername = () => _kc.tokenParsed?.preferred_username
const getParsedToken = () => _kc.tokenParsed
const hasRole = (role) => _kc.hasRealmRole(role)
const getSessionStartTime = () => _kc.tokenParsed?.auth_time

export const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getParsedToken,
  getTokenParsed: getParsedToken,
  onTokenExpired,
  getSessionStartTime,
}
