import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const initI18n = () => {
  return i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      // debug: true,
      fallbackLng: 'en',
      supportedLngs: ['en', 'fr'],
      ns: ['app', 'common'],
      fallbackNS: 'common',
      backend: {
        loadPath: 'locales/{{ns}}/{{lng}}/translation.json',
      },
      detection: {
        lookupQuerystring: 'locale',
        order: ['querystring', 'cookie', 'sessionStorage', 'localStorage', 'navigator'],
        // caches: ['localStorage'], // can enable in future when not in development with regular updates because cloudfront cache invalidation doesn't clear this
      },
    })
}
