import { SVG } from '../../SVG'

const QueenslandLogoCollapsed = ({ style={}, ...props }) => (
  <SVG
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns='http://www.w3.org/2000/svg'
    style={{ "fill": "var(--primary-color)", ...style }}
    {...props}
  >
    <path d="M12.7701 10.7266C9.75739 10.7266 7.31726 13.1689 7.31726 16.1794C7.31726 19.1899 9.75963 21.6322 12.7701 21.6322C13.5686 21.6322 14.3245 21.46 15.0089 21.1536L10.3456 16.3516H16.2323L17.8897 18.0581C18.1044 17.4722 18.2229 16.8392 18.2229 16.1794C18.2229 13.1667 15.7806 10.7266 12.7701 10.7266Z" fill="#003865"/>
    <path d="M0.307739 0.46875V55.7732H55.6122V0.46875H0.307739ZM20.2873 26.5922L18.6859 24.9416C16.9973 26.0823 14.9619 26.751 12.7723 26.751C6.93479 26.751 2.20438 22.0184 2.20438 16.1831C2.20438 10.3478 6.93479 5.61293 12.7701 5.61293C18.6054 5.61293 23.338 10.3456 23.338 16.1809C23.338 18.2989 22.714 20.2716 21.6427 21.9245L26.174 26.59H20.2873V26.5922ZM39.8799 21.5577C39.8799 21.5577 39.0121 25.805 34.6194 26.335C34.6194 26.335 30.4683 27.252 27.8157 25.6104C27.8157 25.6104 24.5346 24.2595 24.3892 21.2669V6.11617H29.4081V17.8427L29.5043 19.869C29.5043 19.869 28.925 21.9177 32.1346 22.0989C32.1346 22.0989 34.5725 22.1369 34.7156 20.5914L34.8118 7.17631L39.8799 12.533V21.5577ZM52.8634 11.2313H48.8107V26.3373H43.7425V11.0859H40.2199L35.5879 6.11617H52.8656V11.2313H52.8634Z" fill="#003865"/>
    <path d="M40.2177 11.0869H43.7403V26.3361H48.8085V11.2323H52.8634V6.11719H35.5857L40.2177 11.0869Z" fill="white"/>
    <path d="M34.7179 20.5947C34.5725 22.138 32.1368 22.1022 32.1368 22.1022C28.9273 21.921 29.5066 19.8723 29.5066 19.8723L29.4104 17.8459V6.11719H24.3915V21.2702C24.5369 24.2627 27.818 25.6136 27.818 25.6136C30.4728 27.2553 34.6217 26.3383 34.6217 26.3383C39.0144 25.8082 39.8822 21.5609 39.8822 21.5609V12.5362L34.814 7.17957L34.7179 20.5947Z" fill="white"/>
    <path d="M23.3403 16.1812C23.3403 10.3437 18.6076 5.61328 12.7723 5.61328C6.93703 5.61328 2.20215 10.3437 2.20215 16.1812C2.20215 22.0187 6.93479 26.7492 12.7701 26.7492C14.962 26.7492 16.9973 26.0827 18.6837 24.9397L20.2851 26.5904H26.1718L21.6404 21.9248C22.714 20.272 23.3358 18.2993 23.3358 16.1812H23.3403ZM17.8919 18.06L16.2346 16.3534H10.3478L15.0112 21.1554C14.329 21.4641 13.5708 21.6341 12.7723 21.6341C9.75962 21.6341 7.31949 19.1917 7.31949 16.1812C7.31949 13.1708 9.76186 10.7284 12.7723 10.7284C15.7828 10.7284 18.2252 13.1708 18.2252 16.1812C18.2252 16.841 18.1066 17.474 17.8919 18.06Z" fill="white"/>
  </SVG>
)

export default QueenslandLogoCollapsed
