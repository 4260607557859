import { SVG } from '../SVG'

const ChevronIcon = ({ style={}, ...props }) => (
  <SVG
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{"stroke": "var(--color-black)", ...style}}
    {...props}
  >
    <g>
      <path
        d="M3.06665 6.89844L11.5 16.0984L19.9333 6.89844"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </g>
  </SVG>
)

export default ChevronIcon
