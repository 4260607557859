import { createContext, useContext, useState } from 'react'

export const AdminUserContext = createContext()

export const AdminUserProvider = ({ children }) => {
  const [tiviLink, setTiviLink] = useState('')
  const [adminUser, setAdminUser] = useState(null)

  const [initialized, setInitialized] = useState(false)

  return (
    <AdminUserContext.Provider
      value={{
        tiviLink,
        setTiviLink,
        adminUser,
        setAdminUser,
        initialized,
        adminKcInitialized: initialized,
        setInitialized,
      }}
    >
      {children}
    </AdminUserContext.Provider>
  )
}

export const useAdminUserContext = () => {
  const PROVIDER_ERROR = 'useAdminUserContext must be used within an AdminUserProvider'
  const context = useContext(AdminUserContext)
  if (!context) {
    throw new Error(PROVIDER_ERROR)
  }
  return context
}

export default AdminUserContext
