import { useTranslation } from 'react-i18next'
import LogoutTimerStyles from './LogoutTimer.module.scss'

const LogoutTimer = ({ sessionTimeRemaining }) => {
  const { t } = useTranslation()

  if (!sessionTimeRemaining || sessionTimeRemaining.includes('NaN')) {
    return null
  }

  const minutes = sessionTimeRemaining.split(' ')[0]

  const minText = sessionTimeRemaining.split(' ')[1]
  const seconds = sessionTimeRemaining.split(' ')[2]
  const secText = sessionTimeRemaining.split(' ')[3]

  return (
    <span className={LogoutTimerStyles['container']}>
      <span className={LogoutTimerStyles['title']}>{t('main.sessionTime')}</span>

      <div className={LogoutTimerStyles['time']}>
        <span className={LogoutTimerStyles['time__minutes-value']}>{minutes}</span>
        <span className={LogoutTimerStyles['time__minutes-label']}>{minText}</span>
        <span className={LogoutTimerStyles['time__seconds-value']}>{seconds}</span>
        <span className={LogoutTimerStyles['time__seconds-label']}>{secText}</span>
      </div>
    </span>
  )
}

export default LogoutTimer
