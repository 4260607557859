import ChevronIcon from '@nv/images/design/chevron-icon'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import TableFooterStyles from './Pagination.module.scss'

export const Pagination = ({ currentPageNum, dataLength, setCurrentPageNum, numVotersPerPage, listItemLabel = '' }) => {
  const { t } = useTranslation()

  const totalPages = Math.max(1, Math.ceil(dataLength / numVotersPerPage))

  return (
    <div className={TableFooterStyles['container']}>
      <span className={TableFooterStyles['text']}>
        {t('admin.electorsList.showing')}
        <span className={TableFooterStyles['value']}>{Math.min(currentPageNum * numVotersPerPage + 1, dataLength)}</span>
        {t('admin.electorsList.to')}
        <span className={TableFooterStyles['value']}>{Math.min(currentPageNum * numVotersPerPage + numVotersPerPage, dataLength)}</span>
        {t('admin.electorsList.of')}
        <span className={TableFooterStyles['value']}>{dataLength}</span>
        {listItemLabel ? t(listItemLabel) : t('admin.electorsList.electors')}
      </span>

      <ReactPaginate
        breakLabel="..."
        nextLabel={<ChevronIcon />}
        previousLabel={<ChevronIcon />}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={totalPages}
        renderOnZeroPageCount={null}
        containerClassName={TableFooterStyles['pagination-container']}
        pageClassName={TableFooterStyles['pagination-item']}
        activeClassName={TableFooterStyles['selected']}
        previousClassName={TableFooterStyles['prev__chevron']}
        nextClassName={TableFooterStyles['next__chevron']}
        breakClassName={clsx(TableFooterStyles['pagination-item'], TableFooterStyles['dots'])}
        forcePage={Math.min(currentPageNum, totalPages - 1)}
        onPageChange={(event) => {
          setCurrentPageNum(event.selected)
        }}
      />
    </div>
  )
}
export default Pagination
