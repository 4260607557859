import { useState } from 'react'
import { useKeycloakSessionTime } from '@nv/hooks'
import { SidebarMobile } from '../../../sidebar/SidebarMobile'
import HamburgerStyles from './Hamburger.module.scss'

export const Hamburger = ({
  links,
  logo,
  hasLanguage = true,
  showCurrentTime = true
}) => {
  const [sideNavbarOpen, setSideNavbarOpen] = useState(false)

  const sessionTimeRemaining = useKeycloakSessionTime()

  return (
    <>
      {sessionTimeRemaining && !sessionTimeRemaining.includes('NaN') ? (
        <div
          className={`${HamburgerStyles['hamburger__icon']} ${sideNavbarOpen ? HamburgerStyles['open'] : ''}`}
          onClick={() => setSideNavbarOpen(!sideNavbarOpen)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') setSideNavbarOpen(!sideNavbarOpen)
          }}
          role="button"
          tabIndex={0}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) : null}

      <div className={HamburgerStyles['hamburger__sidebar-container']}>
        <SidebarMobile
          links={links}
          logo={logo}
          sideNavbarOpen={sideNavbarOpen}
          setSideNavbarOpen={setSideNavbarOpen}
          hasLanguage={hasLanguage}
          showCurrentTime={showCurrentTime} />
      </div>
    </>
  )
}
