import Keycloak from 'keycloak-js'
const { NX_PUBLIC_KEYCLOAK_URL_ADMIN } = process.env
const keycloakConfig = {
  url: NX_PUBLIC_KEYCLOAK_URL_ADMIN,
  clientId: 'voter-portal',
  realm: 'tivi',
}

const _kc = new Keycloak(keycloakConfig)

const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      if (!authenticated) {
        // console.log('user is not authenticated..!')
      } else {
        // console.log('user is authenticated.')
      }
      onAuthenticatedCallback(_kc)
    })
    .catch()
}

const doLogin = _kc.login
const doLogout = _kc.logout
const updateToken = (timeToExpiry) => _kc.updateToken(timeToExpiry)

const onTokenExpired = async (onSessionExpired, timeToExpiry) => {
  try {
    await _kc.updateToken(timeToExpiry)
  } catch (error) {
    onSessionExpired()
  }
}
const getToken = () => _kc.token
const isLoggedIn = () => !!_kc.token

const getUsername = () => _kc.tokenParsed?.preferred_username
const getParsedToken = () => _kc.tokenParsed
const getTokenParsed = () => _kc.tokenParsed
const hasRole = (role) => _kc.hasRealmRole(role)
const getSessionStartTime = () => _kc.tokenParsed?.auth_time

const isSuperAdmin = () => _kc.hasRealmRole('tivi-root-admin') || _kc.hasRealmRole('tivi-user-admin') // queensland only
const isContactInfoRoleAdmin = () => _kc.hasRealmRole('tivi-contactinfo')
const isEventModuleAdmin = () => _kc.hasRealmRole('tivi-event-admin')
const isMonitoringAdmin = () => _kc.hasRealmRole('tivi-auditor')
const isDeveloper = () => _kc.hasRealmRole('developer')

export const KeycloakAdminService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getParsedToken,
  getTokenParsed,
  onTokenExpired,
  getSessionStartTime,
  isSuperAdmin,
  isContactInfoRoleAdmin,
  isEventModuleAdmin,
  isMonitoringAdmin,
  isDeveloper,
}
