import ThumbsUpXIcon from '@nv/images/design/thumbs-up-x-icon'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import ConfirmDenominationModalStyles from './ConfirmDenominationModal.module.scss'

export const ConfirmDenominationModal = ({ modalOpen, onClose, confirm, nomineeName }) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.removeNomination'),
      onClick: confirm,
    },
  ]

  return (
    <ModalWrapper isOpen={modalOpen} onRequestClose={onClose} headerLabel={t('main.unsupportCandidate')} btns={btns}>
      <div className={ConfirmDenominationModalStyles['container']}>
        <ThumbsUpXIcon />
        <span className={ConfirmDenominationModalStyles['title']}>{t('main.youAreDenominating', { name: nomineeName })}</span>
      </div>
    </ModalWrapper>
  )
}
