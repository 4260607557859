import { SVG } from '../SVG'

const FunnelIcon = ({ style = {}, ...props }) => (
  <SVG
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
    {...props}
  >
    <path
      d="M5.26335 6H26.7384C26.9323 6.00006 27.1221 6.05654 27.2845 6.16254C27.447 6.26855 27.5751 6.41951 27.6533 6.59703C27.7314 6.77456 27.7563 6.97098 27.7248 7.16238C27.6933 7.35378 27.6068 7.53188 27.4759 7.675L19.2634 16.7125C19.0924 16.8955 18.9984 17.1371 19.0009 17.3875V24.4625C19.0024 24.6287 18.9619 24.7926 18.8833 24.939C18.8046 25.0854 18.6903 25.2095 18.5509 25.3L14.5509 27.9625C14.4008 28.0613 14.2269 28.1179 14.0474 28.1263C13.868 28.1347 13.6896 28.0945 13.531 28.0101C13.3724 27.9257 13.2394 27.8001 13.1461 27.6466C13.0529 27.493 13.0027 27.3172 13.0009 27.1375V17.3875C13.0033 17.1371 12.9093 16.8955 12.7384 16.7125L4.52585 7.675C4.39492 7.53188 4.30844 7.35378 4.27694 7.16238C4.24545 6.97098 4.27029 6.77456 4.34846 6.59703C4.42662 6.41951 4.55473 6.26855 4.71717 6.16254C4.87962 6.05654 5.06938 6.00006 5.26335 6V6Z"
      fill="none"
      stroke="var(--color-black)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)

export default FunnelIcon
