import { TextAreaField } from '@nv/basic-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ModalWrapper } from '../../ModalWrapper'
import SupportingStatementModalStyles from './SupportingStatementModal.module.scss'

export const SupportingStatementModal = ({ modalOpen, onClose, confirm, initialBio, maxChars = 1000 }) => {
  const { t } = useTranslation()

  const validationSchema = Yup.object({
    // description: Yup.string().required('Description is required'),
  })

  const initialValues = {
    description: initialBio || '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      confirm(values.description)
      onClose()
    },
  })

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.save'),
      onClick: formik.handleSubmit,
    },
  ]

  return (
    <ModalWrapper isOpen={modalOpen} onRequestClose={onClose} headerLabel="Add Supporting Statement" btns={btns}>
      <div className={SupportingStatementModalStyles['container']}>
        <TextAreaField
          formik={formik}
          field="description"
          label="English"
          placeholder="Enter statement here"
          maxChars={maxChars}
          customStyling={{ minHeight: '300px' }}
        />
      </div>
    </ModalWrapper>
  )
}
