import BalletBoxIcon from '@nv/images/design/ballet-box-icon'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import ConfirmArchiveEventStyles from './ConfirmArchiveEventModal.module.scss'

export const ConfirmArchiveEvent = ({ isOpen, onClose, confirm }) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.confirm'),
      onClick: confirm,
    },
  ]

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onClose} headerLabel={t('Cancel/Delete Event')} btns={btns}>
      <div className={ConfirmArchiveEventStyles['container']}>
        <BalletBoxIcon />
        <span className={ConfirmArchiveEventStyles['title']}>{t('Are you sure you want to cancel/delete this event?')}</span>
      </div>
    </ModalWrapper>
  )
}
