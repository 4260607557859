import { SVG } from '../SVG'

const BackArrowIcon = ({ style = {}, ...props }) => (
  <SVG
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
    {...props}
  >
    <path d="M21.0938 12.5H3.90625" stroke="var(--primary-color)" />
    <path d="M10.9375 5.46875L3.90625 12.5L10.9375 19.5312" stroke="var(--primary-color)" />
 </SVG>
)

export default BackArrowIcon
