import { useEffect } from 'react'
import { KeycloakService } from '@nv/auth'
import { useVoterUserContext } from '@nv/contexts'
import { VoterAuthService } from '@nv/services'
import { beforeUnload } from '@nv/utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const useVoterKeyCloakAuth = () => {
  const { initialized, setInitialized, setVoterUser, voterUser } = useVoterUserContext()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  useEffect(() => {
    const initializeSSO = async () => {
      if (!initialized) {
        KeycloakService.initKeycloak(() => {
          setInitialized(true)
        })
      }
      setTimeout(() => {
        setInitialized(true)
      }, 5000)
    }
    if (process.env.NX_PUBLIC_PUBLIC_URL == null) throw new Error('Missing .env file')
    else initializeSSO()
  }, [])

  const history = useHistory()

  const setLoggedInLocal = async () => {
    const tokenParsed = KeycloakService?.getTokenParsed()
    if (tokenParsed) {
      history.push('/voting/voter/dashboard')
      setVoterUser({
        ...tokenParsed,
      })
      const user: any = await VoterAuthService.getUserInfo()

      if (user) {
        const votingChannel = user.votingChannel ?? tokenParsed.votingChannel
        setVoterUser({
          ...tokenParsed,
          ...user,
          votingChannel: votingChannel,
          username: tokenParsed.preferred_username,
          adminView: votingChannel === 'online',
        })
        if (votingChannel === 'online') {
          // const receipt = sessionStorage.getItem('receipt')
          const redirectPath = sessionStorage.getItem('redirectPath')
          if (redirectPath) {
            history.push(`/voting/voter/${redirectPath}`)
            sessionStorage.removeItem('redirectPath')
            //   }
            //  else if (receipt) {
            //     const eventId = sessionStorage.getItem('eventId')
            //     const event = await VoterAuthService.getEventById(eventId)
            //     if (event) {
            //       const hasVoted = user?.votedInEvents?.includes(eventId)
            //       history.push('/election', { event, hasVoted, receipt })
            //     } else {
            //       history.push('/voting/voter/dashboard')
            //     }
          } else {
            history.push('/voting/voter/dashboard')
          }
          window.addEventListener('beforeunload', beforeUnload)
        }
        // setSavedKeycloakLanguage()
      } else {
        clearLocalSession()
      }
    }
  }

  const clearLocalSession = () => {
    window.removeEventListener('beforeunload', beforeUnload)
    if (
      !window.location.pathname.includes('/voting/voter-login/') &&
      !window.location.pathname.includes('/voting/voter/login') &&
      !window.location.pathname.startsWith('/voting/voter-onboarding/phone-verify') &&
      !window.location.pathname.startsWith('/voting/voter-verify/verify') &&
      !window.location.pathname.startsWith('/voting/voter-auth/create-password') &&
      !window.location.pathname.includes('/voting/voter-auth/reset-password') &&
      !window.location.pathname.includes('/voting/voter/single-sign-on') &&
      !window.location.pathname.includes('/registration')
    ) {
      history.push('/')
      setVoterUser({})
    }
  }

  const checkAuth = async () => {
    // is logged in
    if (initialized && KeycloakService?.isLoggedIn()) {
      if (KeycloakService?.isLoggedIn() && voterUser?.votingChannel === 'inPerson') {
        window.removeEventListener('beforeunload', beforeUnload)
        clearLocalSession()
      } else {
        setLoggedInLocal()
      }
    }
    if (initialized && !KeycloakService?.isLoggedIn()) {
      clearLocalSession()
    }
  }

  useEffect(() => {
    checkRedirectParam()
  }, [])

  useEffect(() => {
    checkAuth()
  }, [initialized, KeycloakService?.isLoggedIn()])

  // set language saved in app
  const setSavedKeycloakLanguage = () => {
    if (KeycloakService.isLoggedIn()) {
      VoterAuthService.updateUserLanguage(currentLanguage)
    }
  }
}

const checkRedirectParam = () => {
  var url = new URL(window.location.href)
  const redirectPath = url.searchParams?.get('redirectpath')
  if (redirectPath) {
    sessionStorage.setItem('redirectPath', redirectPath)
  }
}
