import ThumbsUpIcon from '@nv/images/design/thumbs-up-icon'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import ReminderSuccessModalStyles from './ReminderSuccessModal.module.scss'

export const ReminderSuccessModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.ok'),
      onClick: onClose,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={onClose}
      headerLabel={'Send Reminder'}
      btns={btns}
    >
      <div className={ReminderSuccessModalStyles['container']}>
        <ThumbsUpIcon
          className={ReminderSuccessModalStyles['success-icon']}
          color={'var(--success-color)'}
        />
        <span className={ReminderSuccessModalStyles['title']}>
          Emails Sent Successfully
        </span>
      </div>
    </ModalWrapper>
  )
}
