import { Loader } from '@nv/basic-components'
import { useAdminUserContext, useVoterUserContext } from '@nv/contexts'
import { Redirect } from 'react-router-dom'

export const VerifyUserGuard = ({ component: WrappedComponent, redirectURL = '/' }) => {
  const { adminUser, adminKcInitialized } = useAdminUserContext()
  const { voterUser, voterKcInitialized } = useVoterUserContext()
  if (!adminKcInitialized && !voterKcInitialized) {
    return <Loader />
  }
  if ((!adminUser && adminKcInitialized) || (!voterUser && voterKcInitialized)) {
    return <Redirect to={redirectURL} />
  } else {
    return <WrappedComponent />
  }
}
