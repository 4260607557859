import { SVG } from '../SVG'

const JpgIcon = ({ style = {}, ...props }) => (
  <SVG
    width="50"
    height="50"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    style={{"fill": "var(--color-black)", ...style}}
    {...props}
  >
    <path d="M12.5 0C10.7812 0 9.375 1.40625 9.375 3.125V46.875C9.375 48.5938 10.7812 50 12.5 50H43.75C45.4688 50 46.875 48.5938 46.875 46.875V12.5L34.375 0H12.5Z" fill="var(--svg-light-fill)" />
    <path d="M37.5 12.5H46.875L34.375 0V9.375C34.375 11.0938 35.7812 12.5 37.5 12.5Z" fill="var(--svg-darker-fill)" />
    <path d="M46.875 21.875L37.5 12.5H46.875V21.875Z" fill="var(--svg-dark-fill)" />
    <path d="M40.625 40.625C40.625 41.4844 39.9219 42.1875 39.0625 42.1875H4.6875C3.82812 42.1875 3.125 41.4844 3.125 40.625V25C3.125 24.1406 3.82812 23.4375 4.6875 23.4375H39.0625C39.9219 23.4375 40.625 24.1406 40.625 25V40.625Z" fill="#5E6278"/>
    <path d="M39.0625 42.1875H9.375V43.75H39.0625C39.9219 43.75 40.625 43.0469 40.625 42.1875V40.625C40.625 41.4844 39.9219 42.1875 39.0625 42.1875Z" fill="var(--svg-dark-fill)" />
    <path d="M14.2068 34.2824V27.9253H16.0906V34.2824C16.0906 34.8956 15.9562 35.4228 15.6874 35.8638C15.4186 36.3006 15.051 36.6366 14.5848 36.8719C14.1228 37.1071 13.6019 37.2247 13.0223 37.2247C12.4217 37.2247 11.8903 37.1239 11.4283 36.9223C10.9663 36.7206 10.6029 36.4119 10.3383 35.9961C10.0737 35.5761 9.94141 35.0447 9.94141 34.4021H11.8378C11.8378 34.7381 11.884 35.0048 11.9764 35.2022C12.073 35.3997 12.2096 35.5404 12.386 35.6244C12.5624 35.7084 12.7745 35.7504 13.0223 35.7504C13.2617 35.7504 13.4696 35.6937 13.646 35.5803C13.8225 35.4669 13.959 35.3009 14.0556 35.0825C14.1564 34.8641 14.2068 34.5974 14.2068 34.2824Z" fill="var(--color-white)"/>
    <path d="M21.0178 33.8288H18.6803V32.3545H21.0178C21.379 32.3545 21.673 32.2957 21.8998 32.178C22.1266 32.0562 22.2925 31.8882 22.3976 31.674C22.5026 31.4598 22.5551 31.2183 22.5551 30.9495C22.5551 30.6765 22.5026 30.4223 22.3976 30.1871C22.2925 29.9519 22.1266 29.7629 21.8998 29.6201C21.673 29.4773 21.379 29.4059 21.0178 29.4059H19.3356V37.0987H17.4454V27.9253H21.0178C21.736 27.9253 22.3514 28.0555 22.8638 28.3159C23.3804 28.5721 23.7752 28.927 24.0483 29.3807C24.3213 29.8343 24.4578 30.353 24.4578 30.9369C24.4578 31.5291 24.3213 32.0415 24.0483 32.4742C23.7752 32.9068 23.3804 33.2407 22.8638 33.4759C22.3514 33.7111 21.736 33.8288 21.0178 33.8288Z" fill="var(--color-white)"/>
    <path d="M32.7746 32.3041V35.9394C32.6318 36.1116 32.4112 36.2985 32.113 36.5001C31.8148 36.6975 31.4305 36.8677 30.9601 37.0105C30.4896 37.1533 29.9205 37.2247 29.2526 37.2247C28.6604 37.2247 28.1207 37.1281 27.6334 36.9349C27.1462 36.7374 26.7262 36.4497 26.3734 36.0717C26.0247 35.6937 25.7559 35.2316 25.5669 34.6856C25.3779 34.1354 25.2834 33.5074 25.2834 32.8018V32.2285C25.2834 31.5228 25.3737 30.8949 25.5543 30.3446C25.7391 29.7944 26.0016 29.3303 26.3419 28.9522C26.6821 28.5742 27.0874 28.2865 27.5578 28.0891C28.0283 27.8917 28.5512 27.793 29.1266 27.793C29.9247 27.793 30.5799 27.9232 31.0924 28.1836C31.6048 28.4398 31.9954 28.7968 32.2642 29.2547C32.5373 29.7083 32.7053 30.2291 32.7683 30.8172H30.9349C30.8887 30.5063 30.8004 30.2354 30.6702 30.0044C30.54 29.7734 30.3531 29.5928 30.1095 29.4626C29.8701 29.3324 29.5593 29.2673 29.177 29.2673C28.862 29.2673 28.5806 29.3324 28.3328 29.4626C28.0892 29.5886 27.8834 29.7755 27.7153 30.0233C27.5473 30.2711 27.4192 30.5777 27.331 30.9432C27.2428 31.3086 27.1987 31.7328 27.1987 32.2159V32.8018C27.1987 33.2806 27.2449 33.7048 27.3373 34.0745C27.4297 34.4399 27.5662 34.7486 27.7469 35.0006C27.9317 35.2484 28.1585 35.4354 28.4273 35.5614C28.6961 35.6874 29.009 35.7504 29.3661 35.7504C29.6643 35.7504 29.9121 35.7252 30.1095 35.6748C30.3111 35.6244 30.4728 35.5635 30.5946 35.4921C30.7206 35.4165 30.8172 35.3451 30.8845 35.2778V33.6523H29.1581V32.3041H32.7746Z" fill="var(--color-white)"/>
  </SVG>
)

export default JpgIcon
