import { useEffect, useState } from 'react'
import { BackBtn, Loader, Tabs } from '@nv/basic-components'
import BriefcaseIcon from '@nv/images/design/briefcase-icon'
import DividerIcon from '@nv/images/design/divider-icon'
import NominationIcon from '@nv/images/design/nomination-icon'
import { AdminVoterAuthService } from '@nv/services'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CandidatesTab } from './contest_details_tabs/CandidatesTab'
import { NominationLogsTab } from './contest_details_tabs/NominationLogsTab'
import AdminContestDetails from './ContestDetails.module.scss'

export const ContestDetails = () => {
  const { eventId, qid } = useParams()
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const [event, setEvent]: any = useState([])
  const [contestName, setContestName] = useState('')
  const [numOfSeats, setNumOfSeats] = useState(1)
  const [loading, setLoading] = useState(true)

  const fetchEvent = async () => {
    setLoading(true)
    const event: any = await AdminVoterAuthService.getEventById(eventId)
    if (event) {
      setEvent(event)
      const contest = event.questions.find((q) => q.qid === qid)
      setContestName(contest.texts[currentLanguage])
      setNumOfSeats(contest.numOfSeats)
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchEvent()
  }, [])

  if (loading) return <Loader />

  return (
    <>
      <div className={AdminContestDetails['container']}>
        <BackBtn />
        <div className={AdminContestDetails['header']}>
          <div className={AdminContestDetails['header__details']}>
            <NominationIcon />
            <span className={AdminContestDetails['header__title']}>{event.name[currentLanguage]}</span>
            <span className={AdminContestDetails[`header__nominate__${event.nominationState}`]}>
              {t(`events.nominationStatus.${event.nominationState}`)}
            </span>
            <DividerIcon style={{ marginRight: '1.25rem', marginLeft: '1.25rem' }} />
            <span className={AdminContestDetails['header__contests']}>{`${event.questions.length} ${t('Ballots')}`}</span>
          </div>
        </div>
        <div className={AdminContestDetails['ballot-name']}>
          <BriefcaseIcon className={AdminContestDetails['ballot-name__icon']} />
          <div className={AdminContestDetails['ballot-name__text']}>
            <div className={AdminContestDetails['ballot-name__label']}>Ballot Name:</div>
            <div className={AdminContestDetails['ballot-name__value']}>
              {contestName} ({numOfSeats} positions)
            </div>
          </div>
        </div>
        {contestName && (
          <Tabs
            tabs={[
              {
                label: 'Candidates',
                content: <CandidatesTab contestName={contestName} event={event} eventId={eventId} qid={qid} numOfSeats={numOfSeats} />,
              },
              {
                label: 'Nomination Logs',
                content: <NominationLogsTab contestName={contestName} event={event} eventId={eventId} qid={qid} />,
              },
            ]}
          />
        )}
      </div>
    </>
  )
}
