import { useAdminUserContext, useVoterUserContext } from '@nv/contexts'
import { useTranslation } from 'react-i18next'
import WelcomeMessageStyles from './WelcomeMessage.module.scss'

const WelcomeMessage = () => {
  const { t } = useTranslation()
  const VoterUser = useVoterUserContext().voterUser
  const adminUser = useAdminUserContext().adminUser
  const username = adminUser?.username
    ? adminUser.name
      ? adminUser.name
      : adminUser.username
    : VoterUser?.username
    ? VoterUser?.fullName
      ? VoterUser?.fullName
      : VoterUser.username
    : ''

  return <span className={WelcomeMessageStyles['welcome-message']}>{t('main.greeting', { name: username })}</span>
}

export default WelcomeMessage
