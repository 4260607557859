import AdminAxiosInstance from './AdminAxiosInstance'

export const AdminCandidateService = {
  startNominations: async (eventId) => {
    try {
      await AdminAxiosInstance.put(`/events/${eventId}/nominations/started`)
      return { success: true }
    } catch (error) {
      /* empty */
    }
  },
  endNominations: async (eventId) => {
    try {
      await AdminAxiosInstance.put(`/events/${eventId}/nominations/ended`)
      return { success: true }
    } catch (error) {
      /* empty */
    }
  },
  updateNominationEvent: async (eventId, data) => {
    try {
      await AdminAxiosInstance.put(`/events/${eventId}/nominations`, data)
    } catch (error) {
      /* empty */
    }
  },
  getNomineesPosition: async (eventId, qid, limit = 10, skip = 0, search = '', status = '') => {
    try {
      let url = `/events/${eventId}/questions/${qid}/nominees?limit=${limit}&skip=${skip}`
      if (search) {
        url += `&search=${search}`
      }
      if (status) {
        url += `&status=${status}`
      }
      const nomineesData: any = await AdminAxiosInstance.get(url)
      return { nominees: nomineesData.users, numNominees: nomineesData.numUsers }
    } catch (error) {
      /* empty */
    }
  },
  getCandidatesPosition: async (eventId, qid) => {
    try {
      const url = `/events/${eventId}/questions/${qid}/candidates`

      const result: any = await AdminAxiosInstance.get(url, {
        responseType: 'blob',
      })
      return { success: true, data: result }
    } catch (error) {
      /* empty */
    }
  },
  getNominationLogsPosition: async (eventId, qid, limit = 10, skip = 0, search = '', status = '') => {
    try {
      let url = `/events/${eventId}/questions/${qid}/nominationLogs?limit=${limit}&skip=${skip}`
      if (search) {
        url += `&search=${search}`
      }
      if (status) {
        url += `&status=${status}`
      }
      const nomineesData: any = await AdminAxiosInstance.get(url)
      return { nominees: nomineesData.users, numNominees: nomineesData.numUsers }
    } catch (error) {
      /* empty */
    }
  },
  getNominationLogsCsvPosition: async (eventId, qid) => {
    try {
      const url = `/events/${eventId}/questions/${qid}/nominationLogs/csv`

      const result: any = await AdminAxiosInstance.get(url, {
        responseType: 'blob',
      })
      return { success: true, data: result }
    } catch (error) {
      /* empty */
    }
  },
  getNominee: async (nomineeId) => {
    try {
      const nominee = await AdminAxiosInstance.get(`/candidates/${nomineeId}`)
      return { nominee }
    } catch (error) {
      /* empty */
    }
  },
  approveNomineeAsCandidate: async (nomineeId) => {
    try {
      await AdminAxiosInstance.put(`/candidates/${nomineeId}/approve`)
    } catch (error) {
      /* empty */
    }
  },
  declineNomineeAsCandidate: async (nomineeId) => {
    try {
      await AdminAxiosInstance.put(`/candidates/${nomineeId}/decline`)
    } catch (error) {
      /* empty */
    }
  },
  updateNomineeBio: async (nomineeId, data) => {
    try {
      // data includes bio:string
      await AdminAxiosInstance.put(`/candidates/${nomineeId}/bio`, data)
    } catch (error) {
      /* empty */
    }
  },
}
export default AdminCandidateService
