import { useEffect, useRef, useState } from 'react'
import ChevronIcon from '@nv/images/design/chevron-icon'
import clsx from 'clsx'
import { FormikError } from '../formik_error/FormikError'
import { Times } from './presets/Times'
import SelectStyles from './Select.module.scss'

export function Select({
  title = undefined,
  formik = undefined,
  options,
  field = null,
  value = null,
  isRequired = false,
  setValue = null,
}) {
  const [displayValue, setDisplayValue] = useState('')
  const [isDropdownOpen, setIsSelectOpen] = useState(false)
  const topicOpRef = useRef(null)
  const placeholder = title ? title : 'Select an option'
  if (options === 'times') options = Times

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && topicOpRef.current && !topicOpRef.current.contains(e.target)) {
        setIsSelectOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isDropdownOpen])

  useEffect(() => {
    if (value) {
      const selectedOption = options.find((option) => option.value === value)
      if (selectedOption) setDisplayValue(selectedOption.label || selectedOption.id)
    } else {
      setDisplayValue(placeholder)
    }
  }, [value, options, placeholder])

  const handleSelectOption = (item) => {
    setDisplayValue(item.label || item.id)
    if (formik) {
      formik?.setFieldValue(field, item.value)
    } else if (setValue) {
      setValue(item.value)
    }
    setIsSelectOpen(false)
  }

  return (
    <div className={SelectStyles['container']}>
      <label className={SelectStyles['label']}>
        {title} {isRequired && <span className={SelectStyles['required']}>*</span>}
      </label>
      <div
        className={clsx(SelectStyles['select'], { [SelectStyles['error-select']]: formik?.touched[field] && formik?.errors[field] })}
        onClick={() => setIsSelectOpen(!isDropdownOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setIsSelectOpen(!isDropdownOpen)
        }}
        role="button"
        tabIndex={0}
      >
        <span className={clsx({ [SelectStyles['placeholder']]: displayValue === placeholder })}>{displayValue}</span>
        <ChevronIcon
          className={clsx({
            [SelectStyles['chevron-flip']]: isDropdownOpen,
            [SelectStyles['chevron']]: !isDropdownOpen,
          })}
        />
      </div>

      {isDropdownOpen && (
        <div className={SelectStyles['options']} ref={topicOpRef}>
          {options.map((item, index) => (
            <div
              key={index}
              className={SelectStyles['option']}
              onClick={() => handleSelectOption(item)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSelectOption(item)
                }
              }}
              role="button"
              tabIndex={0}
            >
              <span className={SelectStyles['options__text']}>{item.label || item.id}</span>
            </div>
          ))}
        </div>
      )}

      {formik && formik.touched[field] && formik.errors[field] && (
        <FormikError formik={formik} fieldName={field} errorLabel={formik.errors[field]} />
      )}
    </div>
  )
}
