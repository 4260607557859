import { useEffect, useMemo, useState } from 'react'
import { ActionDropdown, DownloadedModal, DownloadingModal, ListTable, NominationStatusFilterModal } from '@nv/components'
import AdminServiceQUT from 'apps/queensland/src/app/services/AdminServiceQUT'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const NUM_VOTERS_PER_PAGE = 50

export const CandidatesTab = ({ contestName, event, eventId, qid, numOfSeats }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const currentLanguage = i18n.language
  const [loadingCandidates, setLoadingCandidates] = useState(true)
  const [tableData, setTableData] = useState([])
  const [tableDataLength, setTableDataLength] = useState(0)
  const [currentPageNum, setCurrentPageNum] = useState(0)
  const [canDownload, setCanDownload] = useState(false)
  const [search, setSearch] = useState('')
  const electorActionOptions = [{ value: 'Edit', label: t('Review') }]
  const [statusFilter, setStatusFilter] = useState('all')
  const [filterModal, setFilterModal] = useState(null)
  const filters = [
    { value: 'all', label: 'All' },
    { value: 'accepted', label: 'Accepted' },
    { value: 'admin-approved', label: 'Admin Approved' },
    { value: 'admin-declined', label: 'Admin Declined' },
  ]
  const [downloadingList, setDownloadingList] = useState(false)
  const [downloadURL, setDownloadURL] = useState(null)
  const [thanksModal, setThanksModal] = useState(false)

  const fetchCandidates = async () => {
    setLoadingCandidates(true)
    const candidates: any = await AdminServiceQUT.getNomineesPosition(
      eventId,
      qid,
      NUM_VOTERS_PER_PAGE,
      currentPageNum * NUM_VOTERS_PER_PAGE,
      search,
      statusFilter,
    )
    if (candidates) {
      setTableData(candidates.nominees)
      setTableDataLength(candidates.numNominees)
    }
    setLoadingCandidates(false)
  }

  useEffect(() => {
    fetchCandidates()
  }, [])

  useEffect(() => {
    const fetchApproved = async () => {
      const approvedCandidates: any = await AdminServiceQUT.getNomineesPosition(
        eventId,
        qid,
        NUM_VOTERS_PER_PAGE,
        0 * NUM_VOTERS_PER_PAGE,
        '',
        'admin-approved',
      )
      if (approvedCandidates) {
        const numApproved = approvedCandidates.numNominees
        if (numApproved >= numOfSeats) setCanDownload(true)
      }
    }
    fetchApproved()
  }, [])

  useEffect(() => {
    setCurrentPageNum(0)
    fetchCandidates()
  }, [search, statusFilter])

  const getDowloadUrl = async () => {
    if (!canDownload) {
      alert('Too few candidates')
      setDownloadingList(false)
      setThanksModal(false)
    } else {
      const res = await AdminServiceQUT.getCandidatesPosition(eventId, qid)
      if (res?.success) {
        const href = URL.createObjectURL(res.data)
        setDownloadURL(href)
        const link = document.createElement('a')
        link.download = `candidates-${event.name[currentLanguage]}-${contestName}`
        link.href = href
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      setDownloadingList(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'nominee.voterId',
        cell: (info) => info.getValue() || '',
        header: () => t('admin.electorsList.voterId'),
      },
      {
        accessorKey: 'nominee.firstName',
        cell: (info) => info.getValue() || '',
        header: () => t('First Name'),
      },
      {
        accessorKey: 'nominee.preferredName',
        cell: (info) => info.getValue() || '',
        header: () => t('Preferred Name'),
      },
      {
        accessorKey: 'nominee.lastName',
        cell: (info) => info.getValue() || '',
        header: () => t('Last Name'),
      },
      {
        accessorKey: 'nominee.email',
        cell: (info) => info.getValue() || '',
        header: () => t('Email'),
      },
      {
        accessorKey: 'nominee.userType',
        cell: (info) => info.getValue() || '',
        header: () => t('Type'),
      },
      {
        accessorKey: 'nominationState',
        cell: (info) => info.getValue() || '',
        header: () => t('admin.status'),
      },
      {
        accessorKey: 'action',
        cell: (info) => (
          <ActionDropdown
            values={electorActionOptions}
            onCallback={() => {
              history.push(`/admin/candidates/${info.row.original._id}`)
            }}
          />
        ),
        header: () => t('buttons.action'),
      },
    ]
  }, [currentLanguage])

  return (
    <>
      {downloadingList ? (
        <DownloadingModal
          isOpen={thanksModal}
          onRequestClose={() => {
            setThanksModal(false)
          }}
          headerLabel={t('admin.exports.candidates')}
          downloadURL={downloadURL}
        />
      ) : (
        <DownloadedModal
          isOpen={thanksModal}
          onRequestClose={() => {
            setThanksModal(false)
          }}
          headerLabel={t('admin.exports.candidates')}
          downloadURL={downloadURL}
        />
      )}

      <NominationStatusFilterModal
        isOpen={filterModal}
        onRequestClose={() => setFilterModal(false)}
        filterOptions={filters}
        propsSelectedOption={statusFilter}
        propsSetSelectedOption={setStatusFilter}
      />

      <ListTable
        data={tableData}
        columns={columns}
        currentPageNum={currentPageNum}
        dataLength={tableDataLength}
        setCurrentPageNum={setCurrentPageNum}
        numVotersPerPage={NUM_VOTERS_PER_PAGE}
        download={getDowloadUrl}
        search={search}
        setSearch={setSearch}
        setDownloadingList={setDownloadingList}
        setThanksModal={setThanksModal}
        loading={loadingCandidates}
        setFilterModal={setFilterModal}
        filter={true}
        listItemLabel="Candidates"
      />
    </>
  )
}
